import { Divider, TableBody, TableCell, TableRow } from "@material-ui/core";
import Fade from '@material-ui/core/Fade';
import { useEffect, useRef, useState } from "react";


const CustomizableListTableBody = ({
  results,
  rowRenderer,
}) => {
  const [visibleResults, setVisibleResults] = useState(results);
  const isVisible= Array.isArray(results) && results.length > 0;

  useEffect(() => {
    if (isVisible){
      setVisibleResults(results);
    }
    else{ 
      setVisibleResults([]);
    }
  }, [results])

  return (
    <Fade in={true} timeout={1000} unmountOnExit={true}>
      <TableBody>
          {
            isVisible 
              ? visibleResults.map((item) => rowRenderer(item)) 
              : <TableRow />
          }
        </TableBody>
    </Fade>
   
  );
}

export default CustomizableListTableBody;
