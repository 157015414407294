import { CircularProgress, Grid } from '@material-ui/core';

const LoadingIndicator = () => (
  <Grid
    container
    alignItems="center"
    justifyContent="center"
    sx={{
      minHeight: '100vh'
    }}
  >
    <Grid
      item
      xs="auto"
    >
      <CircularProgress />
    </Grid>
  </Grid>
);

export default LoadingIndicator;
