import { createContext, useEffect, useReducer, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import {
  tokenIssue,
  tokenRevoke,
  checkIn,
  otpIssue,
  otpRefresh
} from 'src/api/auth';
import { authAxios } from 'src/lib/axios';
import {getThemeOptions} from "../api/queries";
import useSettings from "../hooks/useSettings";

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  hasExpired: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    const permissions = user ? new Set(user.permissions) : new Set();

    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      hasExpired: false,
      user,
      permissions
    };
  },
  LOGIN: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    const permissions = user ? new Set(user.permissions) : new Set();

    return {
      ...state,
      isAuthenticated,
      hasExpired: false,
      user,
      permissions,
    };
  },
  LOGOUT: (state, action) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    permissions: new Set()
  }),
  EXPIRE: (state, action) => ({
    ...state,
    isAuthenticated: false,
    hasExpired: true,
    user: null,
    permissions: new Set()
  })
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  loginOtpIssue: () => Promise.resolve(),
  loginOtpRefresh: () => Promise.resolve(),
  loginTokenIssue: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const getValues = (settings) => ({
  compact: settings.compact,
  direction: settings.direction,
  responsiveFontSizes: settings.responsiveFontSizes,
  roundedCorners: settings.roundedCorners,
  theme: settings.theme,
  themeColor: settings.themeColor ?? "#2596be",
  companyName: settings.companyName ?? "TOKEN MDM",
  logo: settings.logo
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const queryClient = useQueryClient();
  const {settings, saveSettings} = useSettings();
  const [values, setValues] = useState(getValues(settings));

  useEffect(() => {
    saveSettings(values);
  }, [values])

  // Mutations for post requests
  const loginOtpIssue = useMutation(
    (data) => otpIssue.func(data), {
    mutationKey: otpIssue.key
  });

  const loginOtpRefresh = useMutation(
    (data) => otpRefresh.func(data), {
    mutationKey: otpRefresh.key
  });

  const loginTokenIssue = useMutation(
    (data) => tokenIssue.func(data), {
    mutationKey: tokenIssue.key,
    onSuccess: (response, variables, context) => {
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          user: response?.data?.content
        }
      });

      var themeOptions = getThemeOptions.func(null)
      themeOptions.then((response) => {
        setValues(values => ({
          ...values,
          "themeColor": response.color,
          "companyName": response.name,
          "logo": response.icon
        }))
      })
    }
  });

  const logout = useMutation(
    () => tokenRevoke.func(), {
    mutationKey: tokenRevoke.key,
    onSuccess: (response, variables, context) => {
      dispatch({
        type: 'LOGOUT'
      });
      // Invalidate all queries after a logout
      // to get rid of cached data that may leak
      // if a less privileged user logins right after
      queryClient.clear();
    }
  });

  const checkInMutation = useMutation(
    () => checkIn.func(), {
    mutationKey: checkIn.key
  });

  const initialize = async () => {
    checkInMutation.mutate({}, {
      onSuccess: (response) => {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: response?.data?.content
          }
        });
      },
      onError: (error) => {
        // Not authenticated but still initialized
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });
  };

  const expire = async () => {
    dispatch({
      type: 'EXPIRE'
    })
  };

  useEffect(async () => {
    // Initialize without session-expiration
    await initialize();

    authAxios.interceptors.response.use((response) => response,
      async (error) => {
        const request = error.config;
        const status = error.response.status;
        // https://stackoverflow.com/a/53770030 not the same problem, but can be used as workaround
        if (!request.noExpire && (status === 403  || (status === 400 && error.response.data.errors[0].code === 'invalid' && error.response.data.errors[0].field === 'token_refresh'))) {
          // Refresh failed so we are no longer authenticated
          // Expire session to redirect to login
          await expire();
        }

        // This was not a failed token refresh
        // Rethrow error to be handled elsewhere
        throw error;
      }
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        loginOtpIssue,
        loginOtpRefresh,
        loginTokenIssue,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
