import {
  TableRow,
  TableCell,
  Switch,
  Select,
  MenuItem,
  TextField,
  IconButton,
} from '@material-ui/core'

import {Clear, AddCircle, RemoveCircle} from '@material-ui/icons'
import {useState} from 'react'

const renderInputField = (
  columnType,
  filter,
  filterGroup_id,
  dispatchFilterGroups,
  headers
) => {

  const header = headers.filter((header) => header.key === columnType ? true : false)[0];
  
  switch(
    header.renderType
    ){

    case 'text':
      return (
        <TextField
        label="Value"
        fullWidth
        type = {'type' in header.constraints ? header.constraints['type'] : "text"}
        value={filter.value}
        onChange={ event => {
            dispatchFilterGroups({
            type : "UPDATE_VALUE",
            filterGroup_id: filterGroup_id,
            filter_id : filter.id,
            value : event.target.value,
            });
        }}
        />
      )

    case 'select':
      return (
        <Select
          value={filter.value}
          onChange={ event => {
              dispatchFilterGroups({
              type: "UPDATE_VALUE",
              filterGroup_id: filterGroup_id,
              filter_id: filter.id,
              value: event.target.value,
              })
          }}
          >
          {headers
            .filter(header => header.key === columnType)
            [0].fields.map(
              field => <MenuItem key={field.value}  value={field.value}>{field.name}</MenuItem>
            )
          }
        </Select>
      )

    default:
      throw Error();
    
  }
}

const AdvancedFilterRow = ({
  filter,
  filterGroup_id,
  dispatchFilterGroups,
  headers,
}) => {

  const [columnType, setColumnType] = useState(filter.column);

  return (
    <TableRow
    key={filter.id}
    >
      <TableCell
          width="5%"
          align="center"
      >
          <Switch
          size="small"
          color="primary"
          checked={filter.active}
          onClick={() => {
              dispatchFilterGroups(
                  {
                  type: "TOGGLE_STATE",
                  filterGroup_id: filterGroup_id,
                  filter_id: filter.id,
                  }
              )
              }
          }                      
          />
      </TableCell>

      <TableCell
          align="center"
          width="20%"
      >
          <Select
          value={filter.column}
          fullWidth
          onChange={event => {

              setColumnType(event.target.value);

              dispatchFilterGroups({
                  type: "UPDATE_COLUMN",
                  filterGroup_id: filterGroup_id,
                  filter_id: filter.id,
                  value: event.target.value,
                  operator: headers.filter(header => header.key === columnType)[0].operators[0]['value'],
                  renderType: headers.filter(header => header.key === event.target.value)[0].renderType,
              });
            }
          }
          >
          {headers
              .filter(header => header.sortable)
              .map(header => <MenuItem key={header.key} value={header.key}>{header.name}</MenuItem>)
          }
          </Select>
      </TableCell>
        
      <TableCell
          align="center"
          width="5%"
      >
        <IconButton
          onClick={() => dispatchFilterGroups(
            {
              type: "NEGATE",
              filter_id: filter.id,
              filterGroup_id: filterGroup_id
            }
          )}
        >
          {filter.type ? 
          <AddCircle
            color="primary"
          /> : 
          <RemoveCircle
            color="secondary"
          />}
        </IconButton>
        
      </TableCell>

      <TableCell
          align="center"
          width="20%"
      >
        <Select
          value={filter.operator}
          fullWidth
          onChange={ event => {
              dispatchFilterGroups({
              type: "UPDATE_OPERATOR",
              filterGroup_id: filterGroup_id,
              filter_id: filter.id,
              value: event.target.value,
              })
          }}
          >
          {headers
            .filter(header => header.key === columnType)
            [0].operators.map(
              operator => <MenuItem key={operator.value}  value={operator.value}>{operator.display}</MenuItem>
            )
          }
        </Select>
      </TableCell>
      
      <TableCell
        align="center"
        width="45%"
      >
        {renderInputField(columnType, filter, filterGroup_id, dispatchFilterGroups, headers)}
      </TableCell>
        
      <TableCell
          align="center"
          width="5%"
      >
          <IconButton
          onClick={() => dispatchFilterGroups(
              {
              type: "DELETE_FILTER",
              filter_id: filter.id,
              filterGroup_id: filterGroup_id,
              }
          )}
          >
          <Clear
          fontSize="small"                    
          />
          </IconButton>
      </TableCell>

    </TableRow>
  );
}

export default AdvancedFilterRow;