import {Box, Button, Table, TableCell, TableFooter, TableRow,} from "@material-ui/core";
import {AddBox} from "@material-ui/icons";
import {ComplexFilterContext} from "./ComplexFilterContext";
import {useContext} from "react";
import ComplexFilterGroup from "./ComplexFilterGroup";
import DoneIcon from '@material-ui/icons/Done';


const ComplexFilter = ({internalFilterParams, setFilterParams, setInitialFilterGroups, setPage, setBodyParams}) => {
  const {filterGroups, createFilterGroup, setFilterGroup, createFilter} = useContext(ComplexFilterContext);

  return (
    <Table
      size="small"
    >
      {
        Object.entries(filterGroups).map(([groupId, group]) => (
          <ComplexFilterGroup
            key={groupId}
            group={group}
          />
        ))
      }
      <TableFooter>
        <TableRow>
          <TableCell
            colSpan={6}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: "flex-end",
                alignItems: 'baseline',
                my: 1
              }}
            >
              <Button
                variant="outlined"
                startIcon={<AddBox/>}
                onClick={() => {
                  const group = createFilterGroup();
                  const filter = createFilter();
                  group.filters[filter.filterId] = filter;
                  setFilterGroup(group.groupId, group);
                }}
              >
                Add Filter Group
              </Button>
              <Button
                variant="contained"
                startIcon={<DoneIcon/>}
                onClick={() => {
                  setInitialFilterGroups(filterGroups);
                  setPage(0);
                  setFilterParams(internalFilterParams);
                  setBodyParams("");
                }}
                sx={{
                  ml: 3
                }}
              >
                Apply Filters
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
};

export default ComplexFilter;
