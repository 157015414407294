// Validators to be used in forms (generally with Yup).
// Must ensure that these validations are compatible with the backend as well.


// Username as it is accepted by Django: consists of only letters, numbers, and @/./+/-/_ characters
export const USERNAME_VALIDATOR = /^[\w.@+-]+$/;

// Password format, at least 8 characters, one uppercase, one number and one special case character
export const PASSWORD_VALIDATOR = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

// JWT token format.
export const JWT_TOKEN_VALIDATOR = /^[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+\.[A-Za-z0-9_-]+$/;

// Email format, taken from: https://stackoverflow.com/a/32686261/4135580
export const EMAIL_VALIDATOR = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

// String consisting of one or more numbers
export const NUMBER_VALIDATOR = /^\d+$/;

// 24hr time format, where hours and minutes are separated by ':'. e.g. 16:59
export const TIME_24HR_STRING_VALIDATOR = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

// Boolean as string. true (case insensitive), false (case insensitive), 0 or 1.
export const BOOLEAN_STRING_VALIDATOR = /^([Tt][Rr][Uu][Ee]|[Ff][Aa][Ll][Ss][Ee]|1|0)$/;

// Date in yyyyMMdd format. E.g. 20220826
export const DATE_STRING_VALIDATOR = /^(\d{4})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;

// Phone number format: at least 8, at most 16 digits, with an optional + at the beginning
export const PHONE_NUMBER_VALIDATOR = /^\+?1?\d{8,15}$/;

// MAC ID format: six groups of two hexadecimal digits, separated by hyphens (-) or colons (:)
export const MAC_ID_VALIDATOR = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;