import { Box, Button, TableBody, TableCell, TableRow } from "@material-ui/core";
import {AddBox, Delete, } from '@material-ui/icons';
import { useContext } from "react";
import { ComplexFilterContext } from "./ComplexFilterContext";
import ComplexFilterRow from "./ComplexFilterRow";


const ComplexFilterGroup = ({group}) => {
  const {createFilter, setFilter, removeFilterGroup} = useContext(ComplexFilterContext);
  const {groupId} = group;

  return (
    <TableBody
      style={{
        border: "2px solid rgb(200, 200, 200)",
      }}
    >
      {
        Object.entries(group.filters).map(([filterId, filter]) => (
          <ComplexFilterRow
            key={filterId}
            groupId={groupId}
            filter={filter}
          />
        ))
      }
  
      <TableRow>
        <TableCell
          colSpan={6}
          align="center"   
        >
          <Button
            variant="outlined"
            startIcon={<AddBox />}
            onClick={() => {
              const filter = createFilter();
              setFilter(groupId, filter.filterId, filter);
            }}
          >
            Add Filter
          </Button>
          <Button
            variant="outlined"
            startIcon={<Delete />}
            color="secondary"
            onClick={() => removeFilterGroup(groupId)}
            sx={{ ml: 0.5 }}
          >
            Delete Group
          </Button>
        </TableCell>
      </TableRow>
    </TableBody>
  );
};

export {
  ComplexFilterGroup as default,
};
