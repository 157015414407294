import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField
} from '@material-ui/core';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from "@material-ui/lab/DatePicker";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createDevice, editDevice } from 'src/api/mutations';
import {getDeviceDetail, getDeviceList, getVersionFile, getSearchFile} from 'src/api/queries';
import OwnerAutocomplete from "src/components/company/OwnerAutocomplete";
import { ModelTypeAutocomplete } from 'src/components/modeltype';
import { MAC_ID_VALIDATOR } from 'src/constants/validators';
import * as Yup from 'yup';
import ApplicationAutocomplete from "../app/ApplicationAutocomplete";
import ApplicationVersionAutocomplete from "../app/ApplicationVersionAutocomplete";


const DeviceSearchForm = (props) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const currentDate = new Date();
    const defaultDate = currentDate;

    const initialValues = {
      fiscal_id: props.initialValues?.fiscal_id ?? '',
        fiscal_id_min: props.initialValues?.fiscal_id_min ?? '',
        fiscal_id_max: props.initialValues?.fiscal_id_max ?? '',
      connectedat_start: defaultDate,
      connectedat_end: defaultDate,
      connectedat_include: props.initialValues?.connectedat_include ?? '1',
      connected: props.initialValues?.connected??'all',
      fiscal_id_include: props.initialValues?.fiscal_id_include ?? '1',
      model_type: props.initialValues?.model_type ?? null,
      model_type_include: props.initialValues?.model_type_include ?? '1',
      ips: props.initialValues?.fiscal_id ?? '',
      ips_include: props.initialValues?.ips_include??'1',
      fiscal_id_min_max_include: props.initialValues?.fiscal_id_min_max_include??'1',
      status_passive: props.initialValues?.status_passive??'',
      status_active: props.initialValues?.status_active??'',
      status_transfer: props.initialValues?.status_transfer??'',
      status_closed: props.initialValues?.status_closed??'',
      status_junk: props.initialValues?.status_junk??'',
      status_lost : props.initialValues?.status_junk??'',
      sync_bank : props.initialValues?.sync_bank??'all',
      sync_loyalty : props.initialValues?.sync_loyalty??'all',
      sync_food : props.initialValues?.sync_food??'all',
      sync_system : props.initialValues?.sync_system??'all',
      sync_other : props.initialValues?.sync_other??'all',
      sync_parameter  : props.initialValues?.sync_parameter??'all',
      application : props.initialValues?.application?? null,
      application_include : props.initialValues?.application_include?? '1',
      required_version: props.initialValues?.required_version?? null,
      current_version: props.initialValues?.current_version?? null,
    };

    const validationSchema = Yup.object().shape({

        fiscal_id: Yup
          .string()
          .min(8)
          .max(1300),
          //.required('Fiscal ID is required.'),
        fiscal_id_min: Yup
          .string()
          .min(3)
          .max(32),
        fiscal_id_max: Yup
          .string()
          .min(3)
          .max(32),
        fiscal_id_include: Yup
          .string(),
        connectedat_start: Yup
          .date()
          .nullable(),
        connectedat_end: Yup
          .date()
          .nullable(),
        connectedat_include: Yup
            .string(),
        connected: Yup
            .string()
            .max(3),
        model_type: Yup
          .object()
          .nullable(),
        model_type_include: Yup
          .string(),
        ips: Yup
          .string()
          .max(1024),
        ips_include: Yup
          .string(),
        fiscal_id_min_max_include: Yup
          .string(),
        status_passive: Yup
          .string(),
        status_active: Yup
          .string(),
        status_transfer: Yup
          .string(),
        status_closed: Yup
          .string(),
        status_junk: Yup
          .string(),
        status_lost: Yup
          .string(),
        sync_bank: Yup
          .string(),
        sync_loyalty: Yup
          .string()
            .max(3),
        sync_food: Yup
          .string()
            .max(3),
        sync_system: Yup
          .string()
            .max(3),
        sync_other: Yup
          .string()
            .max(3),
        sync_parameter: Yup
          .string()
            .max(3),
        application: Yup
            .object()
            .nullable(),
        application_include: Yup
            .string(),
        required_version: Yup
            .object()
            .nullable(),
        current_version: Yup
            .object()
            .nullable(),
      }).required()

    return (
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {

            const data = {}
            if(values.model_type){
                data['modeltype']=values.model_type.id;
                data['modeltype_include']=values.model_type_include? 1:0;
            }
            if(values.fiscal_id){
                data['fiscalIds']=values.fiscal_id;
                data['fiscalIds_include']=values.fiscal_id_include ? 1:0;
            }
            if(values.fiscal_id_min){
                data['fiscalIds_min_max_min']=values.fiscal_id_min;
                data['fiscalIds_min_max_include']=values.fiscal_id_min_max_include ? 1:0
            }
            if(values.fiscal_id_max){
                data['fiscalIds_min_max_max']=values.fiscal_id_max;
                data['fiscalIds_min_max_include']=values.fiscal_id_min_max_include ? 1:0
            }

            let d = values.connectedat_start;
            const startDate = d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

            d = values.connectedat_end;
            const endDate = d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

            d = currentDate;
            const realDate = d.getDate()+"/"+(d.getMonth()+1)+"/"+d.getFullYear();

            if(startDate!==realDate){
                data['connectedat_start']=startDate;
                data['connectedat_end']=endDate;
                data['connectedat_include']=values.connectedat_include? 1:0;
            }
            if(values.connected){
                data['connected']=values.connected;
            }
            if(values.ips){
                data['ips']=values.ips;
                data['ips_include']=values.ips_include ? 1:0;
            }

            if(values.status_passive){
                data['status_passive']=0;
            }
            if(values.status_active){
                data['status_active']=1;
            }
            if(values.status_transfer){
                data['status_transfer']=2;
            }
            if(values.status_closed){
                data['status_closed']=3;
            }
            if(values.status_junk){
                data['status_junk']=4;
            }
            if(values.status_lost){
                data['status_lost']=5;
            }
            if(values.sync_bank){
                data['type-2']=values.sync_bank;
            }
            if(values.sync_loyalty){
                data['type-3']=values.sync_loyalty;
            }
            if(values.sync_food){
                data['type-4']=values.sync_food;
            }
            if(values.sync_system){
                data['type-1']=values.sync_system;
            }
            if(values.sync_other){
                data['type-5']=values.sync_other;
            }
            if(values.sync_parameter){
                data['parameter']=values.sync_parameter;
            }
            const applicationsArray = [];
            if(values.application) {
                applicationsArray.push({
                    'application_id': values.application.id,
                    'current_version_id': JSON.stringify(values.current_version.id || 'all'),
                    'required_version_id': JSON.stringify(values.required_version.id || 'all'),
                    // Remaining ones needed for backend. Not related with frontend.
                    'current_status': '2',
                    'required_status': '2',
                    'acs_status': '2',
                    'authorized': '2',
                    'statement': values.application_include? 1:0
                    // add other attributes if needed
                })
            }
            if(applicationsArray.length>0){
                data['application'] = JSON.stringify(applicationsArray);
            }
            if(values.application_include){
                data['application_include'] = values.application_include? 1:0;
            }
            console.log("Data being sent:", JSON.stringify(data, null, 2));
            //system, bank, loyalty, food, other type 5.

          props.setBodyParams(data);
          props.setPage(0);
          props.setFilterParams('');
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          isSubmitting,
          setErrors,
          touched,
          values,
          setFieldValue
        }) => (
          <form
            onSubmit={handleSubmit}
            {...props}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid sx={{display:'flex', width:1, flexDirection:'column'}}
                item
              >
                <Box sx={{ marginLeft:0,marginRight:0, display:'flex', flexGrow:1 }}>
                  <Card sx={{flexGrow:1, display:'flex'}}>
                    <CardContent sx={{gap:2, display: 'flex', alignItems: 'baseline', justifyContent: 'flex-end', flexDirection: 'column', flexGrow:1}} >
                      <Box sx={{ my: 2, gap:2, width: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'row'}}>
                        <Box sx={{flexGrow:1}}>
                          <ModelTypeAutocomplete
                            key={props.bodyParams}
                            errors={errors}
                            handleBlur={handleBlur}
                            setFieldValue={setFieldValue}
                            touched={touched}
                            initialModelType={values.model_type}
                            required={false}
                          />
                        </Box>
                        <Box>
                          <FormGroup>
                            <FormControlLabel
                              control={<Switch checked={values.model_type_include}
                                                    color="primary"
                                                    edge="start"
                                                    name="model_type_include"
                                                    onChange={handleChange} />}
                              label={values.model_type_include? "Included":"Excluded"}
                              labelPlacement="top"
                            />
                          </FormGroup>
                        </Box>
                      </Box>
                      <Box sx={{my: 2, gap: 2, display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width: 1}}>
                        <TextField sx={{flexGrow:1}}
                          error={Boolean(touched.fiscal_id && errors.fiscal_id)}
                          helperText={touched.fiscal_id && errors.fiscal_id}
                          label='Fiscal ID'
                          name='fiscal_id'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fiscal_id}
                          variant='outlined'
                          placeholder="AX0000000000"
                          multiline
                          rows={4}
                        />
                        <FormGroup>
                          <FormControlLabel
                              control={<Switch checked={values.fiscal_id_include}
                                                color="primary"
                                                edge="start"
                                                name="fiscal_id_include"
                                                onChange={handleChange} />}
                              label={values.fiscal_id_include? "Included":"Excluded"}
                              labelPlacement="top"
                            />
                        </FormGroup>
                      </Box>
                      <Box sx={{my: 2, gap: 2, display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width: 1}}>
                        <TextField sx={{flexGrow:1}}
                          error={Boolean(touched.fiscal_id_min && errors.fiscal_id_min)}
                          helperText={touched.fiscal_id_min && errors.fiscal_id_min}
                          label='Fiscal ID Min'
                          name='fiscal_id_min'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fiscal_id_min}
                          variant='outlined'
                          placeholder="1"
                        />
                        <TextField sx={{flexGrow:1}}
                          error={Boolean(touched.fiscal_id_max && errors.fiscal_id_max)}
                          helperText={touched.fiscal_id_max && errors.fiscal_id_max}
                          label='Fiscal ID Max'
                          name='fiscal_id_max'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.fiscal_id_max}
                          variant='outlined'
                          placeholder="9"
                        />
                        <FormGroup>
                          <FormControlLabel
                              control={<Switch checked={values.fiscal_id_min_max_include}
                                                color="primary"
                                                edge="start"
                                                name="fiscal_id_min_max_include"
                                                onChange={handleChange} />}
                              label={values.fiscal_id_min_max_include? "Included":"Excluded"}
                              labelPlacement="top"
                            />
                        </FormGroup>
                      </Box>
                      <Box sx={{my: 2, gap: 2, display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width:1}}>
                        <TextField sx={{flexGrow:1}}
                          error={Boolean(touched.ips && errors.ips)}
                          helperText={touched.ips && errors.ips}
                          label='IPs'
                          name='ips'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.ips}
                          variant='outlined'
                          placeholder="XX.XX"
                          multiline
                          rows={4}
                        />
                        <FormGroup>
                          <FormControlLabel
                              control={<Switch checked={values.ips_include}
                                                color="primary"
                                                edge="start"
                                                name="ips_include"
                                                onChange={handleChange} />}
                              label={values.ips_include? "Included":"Excluded"}
                              labelPlacement="top"
                            />
                        </FormGroup>
                      </Box>
                        <Box sx={{my: 2, gap: 2, width: 1, display: 'flex', alignItems: 'flex-start', flexDirection: 'row'}}>
                            <Box sx = {{flexGrow:1}}>
                                <ApplicationAutocomplete
                                    key = {props.bodyParams}
                                    errors = {errors}
                                    handleBlur = {handleBlur}
                                    setFieldValue = {setFieldValue}
                                    touched = {touched}
                                    initialApplication = {values.application}
                                    required= {false}
                                    onChange={(selectedApp) => {
                                        setFieldValue('application', selectedApp);
                                    }}
                                />
                                {/* Application Version Selection Panel */}
                                {values.application && (
                                    <Box sx={{ mt: 2, ml: 2 }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <ApplicationVersionAutocomplete
                                                    key={props.bodyParams}
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched}
                                                    initialVersion={values.current_version}
                                                    application={values.application}
                                                    required={false}
                                                    label={"Current Version"}
                                                    fieldValue={"current_version"}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ApplicationVersionAutocomplete
                                                    key={props.bodyParams}
                                                    errors={errors}
                                                    handleBlur={handleBlur}
                                                    setFieldValue={setFieldValue}
                                                    touched={touched}
                                                    initialVersion={values.required_version}
                                                    application={values.application}
                                                    required={false}
                                                    label={"Required Version"}
                                                    fieldValue={"required_version"}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                )}
                            </Box>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Switch checked={values.application_include}
                                                     color="primary"
                                                     edge="start"
                                                     name="application_include"
                                                     onChange={handleChange} />}
                                    label={values.application_include? "Included":"Excluded"}
                                    labelPlacement="top"
                                />
                            </FormGroup>
                        </Box>
                      <Box sx={{ my: 2, width: 1, flexDirection:'row', display:'flex'}}>
                        <FormControl sx={{flexGrow:1}}>
                            <InputLabel id="sync_bank-label">Bank</InputLabel>
                            <Select
                              labelId="sync_bank-label"
                              value={values.sync_bank}
                              name="sync_bank"
                              label="Bank"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Sync</MenuItem>
                              <MenuItem value={'0'}>Not Sync</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{flexGrow:1}}>
                            <InputLabel id="sync_loyalty-label">Loyalty</InputLabel>
                            <Select
                              labelId="sync_loyalty-label"
                              value={values.sync_loyalty}
                              name="sync_loyalty"
                              label="Loyalty"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Sync</MenuItem>
                              <MenuItem value={'0'}>Not Sync</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{flexGrow:1}}>
                            <InputLabel id="sync_food-label">Food</InputLabel>
                            <Select
                              labelId="sync_food-label"
                              value={values.sync_food}
                              name="sync_food"
                              label="Food"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Sync</MenuItem>
                              <MenuItem value={'0'}>Not Sync</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ my: 1, width: 1, flexDirection:'row', display:'flex'}}>
                        <FormControl sx={{flexGrow:1}}>
                            <InputLabel id="sync_system-label">System</InputLabel>
                            <Select
                              labelId="sync_system-label"
                              value={values.sync_system}
                              name="sync_system"
                              label="System"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Sync</MenuItem>
                              <MenuItem value={'0'}>Not Sync</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{flexGrow:1}}>
                            <InputLabel id="sync_other-label">Other</InputLabel>
                            <Select
                              labelId="sync_other-label"
                              value={values.sync_other}
                              name="sync_other"
                              label="Other"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Sync</MenuItem>
                              <MenuItem value={'0'}>Not Sync</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl sx={{flexGrow:1}}>
                            <InputLabel id="sync_parameter-label">Parameter</InputLabel>
                            <Select
                              labelId="sync_parameter-label"
                              value={values.sync_parameter}
                              name="sync_parameter"
                              label="Parameter"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Sync</MenuItem>
                              <MenuItem value={'0'}>Not Sync</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ my: 2, width: 1}}>
                        <FormControl sx={{width:1}}>
                            <InputLabel id="include-label">Connected</InputLabel>
                            <Select
                              labelId="include-label"
                              value={values.connected}
                              name="connected"
                              label="Connected"
                              onChange={handleChange}
                            >
                              <MenuItem value={'1'}>Connected</MenuItem>
                              <MenuItem value={'0'}>Not Connected</MenuItem>
                              <MenuItem value={'all'}>All</MenuItem>
                            </Select>
                        </FormControl>
                      </Box>
                      <Box sx={{ my: 2, gap:2, display: 'flex', alignItems: 'flex-start', flexDirection: 'row', width: 1}}>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                              disablePast={false}
                              clearable={false}
                              ampm={false}
                              maxDate={currentDate}
                              label="Connected At Start"
                              name='connectedat_start'
                              onBlur={handleBlur}
                              onChange={(newValue) => setFieldValue('connectedat_start', newValue)}
                              value={values.connectedat_start}
                              required={false}
                              renderInput={
                                (props) => <TextField {...props} required={true} />
                              }
                            />
                          </LocalizationProvider>
                          {
                            Boolean(touched.connectedat_start && errors.connectedat_start) && (
                              <FormHelperText error={true} sx={{ mt: 1, ml: 2 }}>
                                {errors.connectedat_start}
                              </FormHelperText>
                            )
                          }
                        </Box>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disablePast={false}
                            clearable={false}
                            ampm={false}
                            minDate={values.connectedat_start}
                            label="Connected At End"
                            name='connectedat_end'
                            onBlur={handleBlur}
                            onChange={(newValue) => setFieldValue('connectedat_end', newValue)}
                            value={values.connectedat_end}
                            required={false}
                            renderInput={
                              (props) => <TextField {...props} required={true} />
                            }
                          />
                        </LocalizationProvider>
                        {
                          Boolean(touched.connectedat_end && errors.connectedat_end) && (
                            <FormHelperText error={true} sx={{ mt: 1, ml: 2 }}>
                              {errors.connectedat_end}
                            </FormHelperText>
                          )
                        }
                        <FormGroup>
                          <FormControlLabel
                              control={<Switch checked={values.connectedat_include}
                                                        color="primary"
                                                        edge="start"
                                                        name="connectedat_include"
                                                        onChange={handleChange} />}
                              label={values.connectedat_include? "Included":"Excluded"}
                              labelPlacement="top"
                            />
                        </FormGroup>
                      </Box>
                      <Box sx={{ my: 2, width: 1}}>
                          <FormGroup sx={{flexDirection:'row', flexGrow:1}}>
                            <FormControlLabel control={<Checkbox checked={values.status_passive}
                                                                 name="status_passive"
                                                                 onChange={handleChange}/>} label="Passive" />
                            <FormControlLabel control={<Checkbox checked={values.status_active}
                                                                 name="status_active"
                                                                 onChange={handleChange}/>} label="Active" />
                            <FormControlLabel control={<Checkbox checked={values.status_transfer}
                                                                 name="status_transfer"
                                                                 onChange={handleChange}/>} label="Transfer" />
                            <FormControlLabel control={<Checkbox checked={values.status_closed}
                                                                 name="status_closed"
                                                                 onChange={handleChange}/>} label="Closed" />
                            <FormControlLabel control={<Checkbox checked={values.status_junk}
                                                                 name="status_junk"
                                                                 onChange={handleChange}/>} label="Junk" />
                            <FormControlLabel control={<Checkbox checked={values.status_lost}
                                                                 name="status_lost"
                                                                 onChange={handleChange}/>} label="Lost" />
                          </FormGroup>
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3
                  }}
                >
                  <Box sx={{ mr: 1}}>
                    <Button
                      onClick={() => {
                      resetForm();
                      props.setBodyParams('');
                      props.setPage(0);
                      props.setFilterParams('');
                    }}
                      color="primary"
                      component={RouterLink}
                      to={`/dashboard/devices/`}
                      variant="outlined"
                    >
                      Clear
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      type='submit'
                      variant='contained'
                    >
                      {'Search'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    );
  };

  export default DeviceSearchForm;