import {
    Table,
    TableCell,
    TableFooter,
    TableRow,
    Button
  } from '@material-ui/core';
import {AddBox, } from '@material-ui/icons';
import AdvancedFilterGroup from './AdvancedFilterGroup';

const AdvancedFilterTable = ({
  headers,
  filterGroups,
  dispatchFilterGroups
}) => {
  
  
  return (    
    <Table
      size="small"
    >
      {filterGroups
        .map(each_filterGroup => (
          <AdvancedFilterGroup 
            key={each_filterGroup.id}
            filterGroup={each_filterGroup}
            headers={headers}
            dispatchFilterGroups={dispatchFilterGroups}
          />
        ))}  
      
      <TableFooter>
        <TableRow>
          <TableCell
            colSpan={5}
            align="center"
          >
            <Button
            variant="contained"
            startIcon={<AddBox />}
            onClick={() => dispatchFilterGroups({type: "ADD_FILTER_GROUP", headers: headers})}
            >
              Add Filter Group
            </Button>
          </TableCell>
        </TableRow>
      </TableFooter>
  
    </Table>
  
  );
}


export default AdvancedFilterTable;