import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('div')`
  display: flex;
  align-items: center;
`;

const LogoImage = experimentalStyled('img')`
  height: 60px; 
  margin-right: 10px; 
`;

const CompanyName = experimentalStyled('h1')`
  font-size: 36px; 
`;

const CompanyLogoHorizontalWithTitle = (props) => {
  /*
      MDM Logo Horizontal with `Token MDM` title.
      Transformed from SVG to React Component using https://svg2jsx.com/
      --------------------------------
      Example usage:

        <MDMLogoHorizontalWithTitle
          sx={{
            height: 200
          }}
        />
    */

  const {logoUrl, companyName, color} = props;
    return (
        <LogoRoot
        >
            {logoUrl !== "" && (
                <LogoImage src={logoUrl} alt="Company Logo"></LogoImage>
            )}
            <CompanyName style={{color}}>{companyName}</CompanyName>
        </LogoRoot>
    );
}

export default CompanyLogoHorizontalWithTitle;
