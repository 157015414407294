import { useState, useEffect, useReducer, useCallback } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
} from '@material-ui/core';
import Scrollbar from '../Scrollbar';
import GenericError from 'src/components/GenericError';
import { LoadingIndicator } from 'src/components/common';
import AdvancedFilterTable from './AdvancedFilterTable';
import ResultTable from './ResultTable';
import { SimpleSearchBar } from 'src/components/common';

const saveObjectToLocalStorage = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj));
}

const filtersReducer = (state, action) => {
  switch (action.type) {
    case "ADD_FILTER_GROUP":
      state.push(
        {
          id : Date.now(),
          filters : [
            {
              id: Date.now(),
              column: action.headers[0].key,
              operator: action.headers[0].operators[0]['value'],
              active: true,
              value: "",
              type: true,
            }
          ]
        }
      );

      return state.slice();

    case "DELETE_FILTER_GROUP":
      state = state.filter( each_filterGroup => (
        each_filterGroup.id !== action.id ? true : false
      ));

      return state;

    case "ADD_FILTER":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.id){
          state[i].filters.push(
            {
              id: Date.now(),
              column: action.headers[0].key,
              operator: action.headers[0].operators[0]['value'],
              active: true,
              value: "",
              type: true,
            }
          );

          state[i] = {
            ...state[i]
          };

          return state.slice();
        }
      }      

      return state
      
    case "DELETE_FILTER":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.filterGroup_id){
          state[i].filters = state[i].filters.filter(
            (each_filter) => each_filter.id !== action.filter_id ? true : false
          );
          if (state[i].filters.length > 0){
            state[i] = {
              ...state[i]
            };

            return state.slice();
          }
          else {
            state = state.filter((each_filterGroup) => each_filterGroup.id === state[i].id ? false : true);
            
            return state;
          }
        }
      }

      return state;
        
    case "TOGGLE_STATE":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.filterGroup_id){
          for(let j=0; j < state[i].filters.length; j++){
            if(state[i].filters[j].id === action.filter_id){
              state[i].filters[j].active = !state[i].filters[j].active;
              
              state[i].filters[j] = {
                ...state[i].filters[j]
              }

              state[i] = {
                ...state[i]
              }
              
              return state.slice()
            }
          }
        }
      }

      return state;
    
    case "UPDATE_COLUMN":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.filterGroup_id){
          for(let j=0; j < state[i].filters.length; j++){
            if(state[i].filters[j].id === action.filter_id){
              state[i].filters[j].column = action.value;
              state[i].filters[j].operator = action.operator;

              switch(action.renderType){
                case 'text':
                  state[i].filters[j].value = '';
                  break;
                case 'select':
                  state[i].filters[j].value = 0;
                  break;
                default:
                  throw new Error();
              }


              state[i].filters[j] = {
                ...state[i].filters[j]
              }

              state[i] = {
                ...state[i]
              }
              
              return state.slice()
            }
          }
        }
      }

      return state;

    case "UPDATE_OPERATOR":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.filterGroup_id){
          for(let j=0; j < state[i].filters.length; j++){
            if(state[i].filters[j].id === action.filter_id){
              state[i].filters[j].operator = action.value;
              
              state[i].filters[j] = {
                ...state[i].filters[j]
              }

              state[i] = {
                ...state[i]
              }
              
              return state.slice()
            }
          }
        }
      }

      return state;
    
    case "UPDATE_VALUE":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.filterGroup_id){
          for(let j=0; j < state[i].filters.length; j++){
            if(state[i].filters[j].id === action.filter_id){
              state[i].filters[j].value = action.value;
              
              state[i].filters[j] = {
                ...state[i].filters[j]
              }

              state[i] = {
                ...state[i]
              }
              
              return state.slice()
            }
          }
        }
      }

      return state;

    case "NEGATE":
      for(let i=0; i < state.length; i++){
        if(state[i].id === action.filterGroup_id){
          for(let j=0; j < state[i].filters.length; j++){
            if(state[i].filters[j].id === action.filter_id){
              state[i].filters[j].type = !state[i].filters[j].type;
              
              state[i].filters[j] = {
                ...state[i].filters[j]
              }

              state[i] = {
                ...state[i]
              }
              
              return state.slice()
            }
          }
        }
      }

      return state;

    default:
      throw new Error();
  }
};

const selectedRowsReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_SELECT_ALL":
      if(action.select){
        action.query_results.forEach((each) => state.add(each[action.selectableField]));
      }
      else{
        action.query_results.forEach((each) => state.delete(each[action.selectableField]));
      }
      return new Set(state);

    case "TOGGLE_ROW":
      const v = action.selectableFieldValue
      state.has(v) ? state.delete(v) : state.add(v);

            
      return new Set(state);
      
    default:
      throw new Error();
  }
};


const ListTable = (props) => {
  const { 
    headers,
    queryDef,
    disableSearch,
    defaultOrdering,
    isSelectable,
    selectableField,
    detailUrl,
    detailUrlField
  } = props;

  // TODO Look for a cleaner approach to defaults
  const defaultPage = 0;
  const defaultPageSize = 10;

  const [hovered, setHovered] = useState(null);
  const [ordering, setOrdering] = useState(defaultOrdering);
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [queryParams, setQueryParams] = useState('');
  const [searchParams, setSearchParams] = useState('');

  const [filterGroups, dispatchFilterGroups] = useReducer(
    filtersReducer,
    []
  );

  const [selectedRows, dispatchSelectedRows] = useReducer(
    selectedRowsReducer,
    new Set()
  );
  
  useEffect(() => {
    const urlSearchParams = new URLSearchParams();

    if (searchParams && searchParams !== '') {
      urlSearchParams.append('search', searchParams);
    }
    if (ordering) {
      urlSearchParams.append('ordering', ordering);
    }
    if (page) {
      // Table pagination is 0-indexed
      // but Django is 1-indexed, hence page+1
      urlSearchParams.append('page', page+1);
    }
    if (pageSize) {
      urlSearchParams.append('page_size', pageSize);
    }
    if (filterGroups) {
      const cleaned_filterGroups = [];
      for (const each_filterGroup of filterGroups) {
        const group = [];
        for (const each_filter of each_filterGroup.filters) {
          if (each_filter.value && each_filter.active) {
            group.push(
              {
                c: each_filter.column,
                o: each_filter.operator,
                v: each_filter.value,
                n: each_filter.type ? 0 : 1,
              }
            );
          }
        }
        cleaned_filterGroups.push(group);
      }
      const filterGroupsJSON = JSON.stringify(cleaned_filterGroups);

      urlSearchParams.append('complex-filter', filterGroupsJSON);
    }

    let paramString = urlSearchParams.toString();
    setQueryParams(paramString);
  }, [searchParams, ordering, page, pageSize, filterGroups]);

  const query = useQuery(
    [queryDef.key, queryParams],
    () => queryDef.func(queryParams), {
      placeholderData: {
        count: 0,
        results: []
      }
    }
  );

  if (query.isError) {
    console.error(query.error);
    return <GenericError error={query.error} />;
  }

  return (    
    <Card sx={{ mt: 3 }}>
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          { disableSearch !== true && (
          <>
            <Box sx={{ p: 3 }}>
              <SimpleSearchBar
                searchParams={searchParams}
                setSearchParams={setSearchParams}
              />
            </Box>
            <Divider />
            <Box sx={{ p: 1}}>
              <AdvancedFilterTable
                headers={headers}
                filterGroups={filterGroups}
                dispatchFilterGroups={dispatchFilterGroups}
              />
            </Box>     
            <Divider />    
          </>
          )}

          <ResultTable 
            headers={headers}
            query={query}
            ordering={ordering}
            setOrdering={setOrdering}
            hovered={hovered}
            setHovered={setHovered}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            isSelectable={isSelectable}
            selectableField={selectableField}
            dispatchSelectedRows={dispatchSelectedRows}
            selectedRows={selectedRows}
            detailUrl={detailUrl}
            detailUrlField={detailUrlField}
            defaultPageSize={defaultPageSize}
          />          
        </Box>
      </Scrollbar>
    </Card>
  );
};

ListTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    align: PropTypes.string,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    orderable: PropTypes.bool
  })).isRequired,
  queryDef: PropTypes.shape({
    key: PropTypes.string.isRequired,
    func: PropTypes.func.isRequired
  }).isRequired,
  disableSearch: PropTypes.bool,
  defaultOrdering: PropTypes.string
};

export default ListTable;
