import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText,
  Grid, TextField, Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { createModelType, editModelType } from 'src/api/mutations';
import { getModelTypeList, getModelTypeDetail, getParameterList } from 'src/api/queries';
import { LoadingIndicator } from 'src/components/common';
import GenericError from 'src/components/GenericError';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import PermissionGate from "../../permission-management/PermissionGate";

const CheckboxGroup = (props) => {
  // ----------------------------------------------
  // Checkbox group component for the Parameters.
  // ----------------------------------------------
  const { disabled, checked, setChecked, error, setErrors, setFieldValue, query } = props;
  const [parameterSet, setParameterSet] = useState(new Set(checked));
    
  if (query.isLoading) {
    return <LoadingIndicator />;
  }

  if (query.isError) {
    console.error(query.error);
    setErrors({ parameters: 'Could not retrieve parameter list.' });
    return <GenericError error={query.error} />;
  }

  const toggleCheckboxValue = (item) => {
    const newSet = new Set(parameterSet);
    if (newSet.has(item.id)) {
      newSet.delete(item.id);
    }
    else {
      newSet.add(item.id);
    }
    setParameterSet(newSet);    // set the modelTypeSet with the new values
    const newCheckedList = Array.from(newSet);
    setChecked(newCheckedList); // forward the new values to the array.
    setFieldValue('parameters', newCheckedList);
  };

  return (
    <Card
        sx={{ display: 'inline-block', minWidth: 500}}
      >
      <FormControl
        error={Boolean(error)}
      >
        <FormGroup
          row={false}
        >
          <Grid container spacing={2}>
            {query.data.results.map( (item) => (
                <Grid item xs={4}>
                  <FormControlLabel
                    key={`checkbox-group-parameters-form-control-label-${item.id}`}
                    control={
                      <Checkbox sx={{ ml: 2 }}
                        key={`parameter-checkbox-${item.id}`}
                        name={item.name}
                        checked={parameterSet.has(item.id)}
                        onChange={() => toggleCheckboxValue(item)}
                        color="success"
                        disabled={disabled}
                      />
                    }
                    label={item.name}
                  />
                </Grid>
            ))}
          </Grid>
        </FormGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Card>
  );
}   

const ModelTypeForm = (props) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const formAction = props.formAction ?? 'create';
  const modelTypeId = (props.formAction === 'edit') ? props?.initialValues.id : null;
  const [checkedParameters, setCheckedParameters] = useState(props?.initialValues?.parameters.map(parameter => parameter.id) ?? []);


  // Get parameters as a list (to put them later into checkboxes)
  const getParameterListQuery = useQuery(
    [getParameterList.key],
    () => getParameterList.func(`page_size=300`)     // Default page size = 10 might not be enough to fetch all parameters.
  );

  const mutation = (formAction === 'create' ? (
    useMutation(
      (formData) => createModelType.func(formData),
      {
        mutationKey: createModelType.key,
        onSuccess: () => queryClient.invalidateQueries(getModelTypeList.key)
      }
    )
  ) : (
    useMutation(
      (formData) => editModelType.func(modelTypeId, formData),
      {
        mutationKey: editModelType.key,
        onSuccess: () => queryClient.invalidateQueries([getModelTypeDetail.key, modelTypeId])
      }
    )
  ));

  const initialValues = {
    id: modelTypeId ?? '',
    name: props?.initialValues?.name ?? '',
    visual_name: props?.initialValues?.visual_name ?? '',
    prefix: props?.initialValues?.prefix ?? '',
    receipt_header_size: props?.initialValues?.receipt_header_size ?? null,
    parameters: checkedParameters
  };

  const validationSchema = Yup.object().shape({
    id: Yup.number().min(1),
    name: Yup.string().max(255).required('Name field is required.'),
    visual_name: Yup.string().max(255).required('Actual Name field is required.'),
    prefix: Yup.string().max(32).required('Prefix is required.'),
    receipt_header_size: Yup.number().min(1).nullable(),
    parameters: Yup.array()
      .required('Picking Parameters is required.')
  }).required();

  const onSubmit = async (values, { setErrors, setStatus, setSubmitting }) => {
    
    if (!values.id) {
      delete values.id;
    }

    if(!values.receipt_header_size) {   // If receipt_header_size is not entered, then
      values.receipt_header_size = 0;   // assign it to 0, as it is the default value of it in the Backend.
    }

    mutation.mutate(values, {
      onSuccess: () => {
        setStatus({ success: true });
        setSubmitting(false);
        enqueueSnackbar(
          (formAction === 'create')
          ? 'Model Type Created Successfully'
          : 'Model Type Edited Successfully',
          {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'success'
          }
        );
        if (formAction === 'create') {
          navigate('/dashboard/modeltypes');
        } else {
          navigate(`/dashboard/modeltypes/${modelTypeId}`);
        }
      },
      onError: (error) => {
        console.error(error);
        setStatus({ success: false });
        setErrors({ submit: Object.values(error.response.data.errors[0].message) });
        setSubmitting(false);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {
        ({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setErrors,
          setFieldValue,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            {...props}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={8}
                md={6}
                xs={12}
              >
                <Box sx={{ mt: 3 }}>
                  <Card>
                    <CardHeader title='Fill Model Type Information' />
                    <CardContent>
                      <Box>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label='Name'
                          name='name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          variant='outlined'
                          placeholder="RO400"
                          required={true}
                        />
                      </Box>
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.visual_name && errors.visual_name)}
                          fullWidth
                          helperText={touched.visual_name && errors.visual_name}
                          label='Visual Name'
                          name='visual_name'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.visual_name}
                          variant='outlined'
                          placeholder="RO400 Android POS Device"
                          required={true}
                        />
                      </Box>  
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.prefix && errors.prefix)}
                          fullWidth
                          helperText={touched.prefix && errors.prefix}
                          label='Model Prefix'
                          name='prefix'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.prefix}
                          variant='outlined'
                          placeholder="AX"
                          required={true}
                        />
                      </Box>
                      
                      <Box sx={{ mt: 2 }}>
                        <TextField
                          error={Boolean(touched.receipt_header_size && errors.receipt_header_size)}
                          helperText={touched.receipt_header_size && errors.receipt_header_size}
                          label='Receipt Header Size'
                          name='receipt_header_size'
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.receipt_header_size}
                          variant='outlined'
                        />
                      </Box>
                    </CardContent>
                    <PermissionGate permissions={'commonlib.view_parameter'}>
                      <CardHeader title='Parameters To Assign *' />
                      <CardContent>
                        <CheckboxGroup
                          checked={values.parameters}
                          setChecked={setCheckedParameters}
                          error={errors.parameters}
                          setErrors={setErrors}
                          setFieldValue={setFieldValue}
                          name='parameters'
                          query={getParameterListQuery}
                        />
                      </CardContent>                    
                    </PermissionGate>
                  </Card>
                </Box>
                {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                      {errors.submit}
                    </FormHelperText>
                  </Box>
                )}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: 3
                  }}
                >
                  <Box sx={{ mr: 1 }}>
                    <Button
                      color="primary"
                      component={RouterLink}
                      to={
                        formAction === 'create' 
                          ? `/dashboard/modeltypes` 
                          : `/dashboard/modeltypes/${modelTypeId}`
                      }
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      color='primary'
                      disabled={isSubmitting}
                      type='submit'
                      variant='contained'
                    >
                      {formAction === 'create' ? 'Create' : 'Edit'}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        )
      }
    </Formik>
  );
};

ModelTypeForm.propTypes = {
  id: PropTypes.number,
  formAction: PropTypes.oneOf(['create', 'edit']),
  initialValues: PropTypes.shape({
    name: PropTypes.string.isRequired,
    visual_name: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    receipt_header_size: PropTypes.number.isRequired,
    parameters: PropTypes.array.isRequired
  })
};

export default ModelTypeForm;
