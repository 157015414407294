import {
  Box,
  Card, CardContent, CardHeader, Chip, Divider, Stack, Table, TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import { getModelTypeParameters } from "src/api/queries";
import GenericError from "src/components/GenericError";
import PermissionGate from 'src/permission-management/PermissionGate';
import { LoadingIndicator } from '../common';
import Scrollbar from '../Scrollbar';


const ModelTypeParameterChips = ({modelType}) => {
  const navigate = useNavigate(); 
  const queryParams = `page_size=300`;

  const query = useQuery(
    [getModelTypeParameters(modelType.id).key, modelType.id],
    () => getModelTypeParameters(modelType.id).func(queryParams), {}
  );

  if (query.isLoading) {
    return <LoadingIndicator/>;
  }

  if (query.isError) {
    console.error(query.error);
    return <GenericError error={query.error}/>;
  }

  const modelTypeParameters = query.data;

  return (
    <>
      <CardHeader title="Parameters on This Model" />
      <Divider />
      <CardContent>
        <Box sx={{ minWidth: 700 }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          {modelTypeParameters.results.map(modelTypeParameter => (
            <Chip
              key={`parameter-chip-${modelTypeParameter.parameter.id}`}
              label={
                <Typography margin={3} variant="h6">{modelTypeParameter.parameter.name}</Typography>
              }
              size="huge"
              variant="outlined"
              clickable
              onClick={() => {
                navigate(`/dashboard/parameters/${modelTypeParameter.parameter.id}`)
              }}
            />
          ))}
        </Stack>
        </Box>
      </CardContent>
    </>
  )
}


const ModelTypeDetailTable = (props) => {
  const { modelType, ...other } = props;
  
  return (
    <Card {...other}>
      <CardHeader title="Details" />
      <Divider />
      <CardContent>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="25%" >
                    <Typography variant="h6" >
                      Created
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    <Typography variant="h6" >
                      Updated
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    <Typography variant="h6" >
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    <Typography variant="h6" >
                      Visual Name
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" width="25%" >
                    {`${format(new Date(modelType.created_at), 'dd-MMM-yyyy HH:mm:ss')}`}
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    {`${format(new Date(modelType.updated_at), 'dd-MMM-yyyy HH:mm:ss')}`}
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    {modelType.name}
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    {modelType.visual_name}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" width="25%" >
                    <Typography variant="h6" >
                      Prefix
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    <Typography variant="h6" >
                      Receipt Header Size
                    </Typography>
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                <TableCell align="center" width="25%" >
                    { modelType.prefix }
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    { modelType.receipt_header_size }
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    
                  </TableCell>
                  <TableCell align="center" width="25%" >
                    
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </Scrollbar>
      </CardContent>
      <PermissionGate permissions={['commonlib.view_parameter', 'commonlib.view_modeltypeparameter']}>
        <ModelTypeParameterChips modelType={modelType} />
      </PermissionGate>
    </Card>
  );
};

ModelTypeDetailTable.propTypes = {
  modelType: PropTypes.object.isRequired
};

export default ModelTypeDetailTable;
