import {
  Autocomplete, TextField
} from "@material-ui/core";
import { useState } from "react";
import { useQuery } from "react-query";
import { getApplicationVersions} from "src/api/queries";


const ApplicationVersionAutocomplete = (props) => {
  const { disabled, errors, required, handleBlur, initialVersion, application, extraQueryParams ,setErrors, setFieldValue, autoSelect, touched, afterChange } = props;
  const [isFirst, setIsFirst] = useState(true);
  const [value, setValue] = useState(initialVersion);
  const [search, setSearch] = useState('');

  // queryParams should be able to utilize extra info such as Package Type which can be inputted via RadioButton etc.
  const queryParams = extraQueryParams ? `page_size=300&visual_name__icontains=${search}&${extraQueryParams}` : `page_size=300&visual_name__icontains=${search}`;
  const query = useQuery(
    [getApplicationVersions(application.id).key, 'autocomplete', { params: queryParams }],
    () => getApplicationVersions(application.id).func(queryParams)
  );

  const labels = props?.label ?? 'Version';
  const fieldValue = props?.fieldValue ?? 'version';

  if (query.isError) {
    setErrors({ owner: 'Could not retrieve application version list.' });
  }

  const options = isFirst && initialVersion
      ? [initialVersion]
      : (query.isFetching ? [] : query.data.results);

  return (
    <Autocomplete
      id='application-autocomplete'
      disabled={disabled || query.isError}
      loading={query.isFetching}
      loadingText='Loading application versions...'
      autoSelect={autoSelect}
      onInputChange={(_, v) => {
        setSearch(v);
        setIsFirst(false);
      }}
      onChange={(_, v) => {
          setValue(v);
          setFieldValue(fieldValue, v || null);
          if (typeof afterChange === 'function') {
              afterChange(_, v); // Only call afterChange if it's a function and
                                // For changes that will be applied whenever this component's input changes.
          }
      }}
      onOpen={handleBlur}
      options={options}
      renderInput={(params) => (
        <TextField {...params}
          label={labels}
          error={Boolean(touched.application && errors.application)}
          helperText={touched.application && errors.application}
          fullWidth
          name='application'
          variant='outlined'
          required={required}
        />
      )}
      getOptionSelected={(option, value) => option.id === value.id}
      // TODO: ask for naming convention????
      getOptionLabel={(option) => option ? `${option.code ?? '-'} - ${option.model_types.map(mt => mt.visual_name).join(', ') ?? '-'}` : ''}
      value={value}
    />
  );
};

export default ApplicationVersionAutocomplete;