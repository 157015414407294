import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import Scrollbar from '../Scrollbar';
import useSimpleSearchBar from 'src/hooks/useSimpleSearchBar';
import GenericError from 'src/components/GenericError';
import { LoadingIndicator } from 'src/components/common';
import OrderingIconButton from './OrderingIconButton';

const SimpleListTable = (props) => {
  const { 
    headers,
    rowMapper,
    queryDef,
    disableSearch,
    defaultOrdering,
    title
  } = props;

  // TODO Look for a cleaner approach to defaults
  const defaultPage = 0;
  const defaultPageSize = 10;

  const [hovered, setHovered] = useState(null);
  const [simpleSearchBar, searchParams] = useSimpleSearchBar();
  const [ordering, setOrdering] = useState(defaultOrdering ?? '');
  const [filter, setFilter] = useState(null);
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [queryParams, setQueryParams] = useState('');
  
  useEffect(() => {
    const urlSearchParams = new URLSearchParams();
    if (searchParams && searchParams !== '') {
      urlSearchParams.append('search', searchParams);
    }
    if (ordering) {
      urlSearchParams.append('ordering', ordering);
    }
    if (filter) {
      urlSearchParams.append(filter.name, filter.value);
    }
    if (page) {
      // Table pagination is 0-indexed
      // but Django is 1-indexed, hence page+1
      urlSearchParams.append('page', page+1);
    }
    if (pageSize) {
      urlSearchParams.append('page_size', pageSize);
    }

    let paramString = urlSearchParams.toString();
    setQueryParams(paramString);
  }, [searchParams, ordering, filter, page, pageSize]);

  const queryKey = Array.isArray(queryDef.key) ? [...queryDef.key, queryParams] : [queryDef.key, queryParams];

  const query = useQuery(
    queryKey,
    () => queryDef.func(queryParams)
  );

  if (query.isLoading) {
    return <LoadingIndicator />;
  }

  if (query.isError) {
    console.error(query.error);
    return <GenericError error={query.error} />;
  }

  return (
    <Card sx={{ mt: 3 }}>
      {
        title ? (
          <>
            <CardHeader title={title} />
            <Divider />
          </>
        ) : null
      }
      <CardContent>
        <Scrollbar>
          <Box sx={{ minWidth: 700 }}>
            { disableSearch !== true && (
            <>
              <Box sx={{ p: 3 }}>
                {simpleSearchBar}
              </Box>
              <Divider />
            </>
            )}
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((item) => {
                    const align = item?.align || 'left';
                    return (
                      <TableCell
                        key={item.key}
                        align={align}
                        onMouseEnter={() => setHovered(item.key)}
                        onMouseLeave={() => setHovered(null)}
                      >
                        <Box>
                          {item.name}
                          <Box
                            sx={{
                              display: 'inline',
                              marginLeft: 1
                            }}
                          >
                            <OrderingIconButton
                              fieldKey={item.key}
                              ordering={ordering}
                              setOrdering={setOrdering}
                              visible={item.sortable && hovered === item.key}
                            />
                          </Box>
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              {
                (Array.isArray(query.data.results) && query.data.results.length)
                ? (
                  <TableBody>
                    {query.data.results.map((item) => rowMapper(item))}
                  </TableBody>
                )
                : null
              }
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={query.data.count}
                    page={page}
                    onPageChange={(e, newPage) => setPage(newPage)}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(e) => {
                      setPageSize(parseInt(e.target.value, defaultPageSize));
                      setPage(0);
                    }}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </Box>
        </Scrollbar>
      </CardContent>
    </Card>
  );
};

SimpleListTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    align: PropTypes.string,
    sortable: PropTypes.bool,
    filterable: PropTypes.bool,
    orderable: PropTypes.bool
  })).isRequired,
  rowMapper: PropTypes.func.isRequired,
  queryDef: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]).isRequired,
    func: PropTypes.func.isRequired
  }).isRequired,
  disableSearch: PropTypes.bool,
  defaultOrdering: PropTypes.string,
  title: PropTypes.string
};

export default SimpleListTable;
