import {
  Table
} from '@material-ui/core'
import { memo } from 'react';
import ResultTableBody from './ResultTableBody';
import ResultTableFooter from './ResultTableFooter';
import ResultTableHeader from './ResultTableHeader';

const ResultTable = ({
  headers,
  query,
  ordering,
  setOrdering,
  hovered,
  setHovered,
  page,
  setPage,
  pageSize,
  setPageSize,
  isSelectable,
  selectableField,
  dispatchSelectedRows,
  selectedRows,
  detailUrl,
  detailUrlField,
  defaultPageSize
}) => {
  return (
    <Table>
      <ResultTableHeader 
        headers={headers}
        setHovered={setHovered}
        hovered={hovered}
        setOrdering={setOrdering}
        ordering={ordering}
        isSelectable={isSelectable}
        selectableField={selectableField}
        dispatchSelectedRows={dispatchSelectedRows}
        selectedRows={selectedRows}
        query_results={query.data.results}
      />

      <ResultTableBody
        query_results={query.data.results}
        isSelectable={isSelectable}
        selectableField={selectableField}
        dispatchSelectedRows={dispatchSelectedRows}
        selectedRows={selectedRows}
        headers={headers}
        detailUrl={detailUrl}
        detailUrlField={detailUrlField}
      />

      <ResultTableFooter
        query_count={query.data.count}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        defaultPageSize={defaultPageSize}
      />
    </Table>
  );
};

export default memo(ResultTable);
