import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from "@material-ui/core";
import {useEffect, useState} from "react";
import {useQuery} from "react-query";
import GenericError from "src/components/GenericError";
import Scrollbar from "src/components/Scrollbar";
import CustomizableListSearch from "./CustomizableListSearch";
import CustomizableListTableBody from "./CustomizableListTableBody";
import CustomizableListTableFooter from "./CustomizableListTableFooter";
import CustomizableListTableHeader from "./CustomizableListTableHeader";
import {getSearchList} from "../../../api/queries";
import {da} from "date-fns/locale";


const CustomizableListBase = ({
                                queryParams,
                                setQueryParams,
                                tableHeaders,
                                filterHeaders,
                                queryDef,
                                customRowRenderer,
                                defaultOrdering,
                                disableOrdering,
                                disablePagination,
                                disableSearch,
                                disableComplexFilter,
                                advancedSearch,
                                tableMaxHeight,
                                filterGroups,
                                setFilterGroups,
                                searchParams,
                                setSearchParams,
                                setResultCount,
                                extraQueryMethod
                              }) => {
  const [isInited, setIsInited] = useState(false);
  const [ordering, setOrdering] = useState(defaultOrdering ?? '');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [filterParams, setFilterParams] = useState('');
  const [bodyParams, setBodyParams] = useState('');

  // Variable for toggling the search and filter panel. 
  const [openCollapse, setOpenCollapse] = useState(false);

  const [toggleSearch, setToggleSearch] = useState(false);

  const skeletonCount = 5;
  const SkeletonRenderer = () => (
    <TableBody>
      {[...Array(skeletonCount).keys()].map(item => (
        <Fade in={true} timeout={300} unmountOnExit={true}>
          <TableRow
            key={`skeleton-${item}`}
          >
            {tableHeaders.map((tc => (
              <TableCell
                key={`skeleton-${item}-cell-${tc.machineName}`}
              >
                <Box sx={{my: 1, width: "80%"}}>
                  <Skeleton height={22} animation="wave" variant="text"/>
                </Box>
              </TableCell>
            )))}
          </TableRow>
        </Fade>
        
      ))}
    </TableBody>
  )


  useEffect(() => {
    if (!isInited) {
      setIsInited(true);
    } else {
      const urlQueryParams = new URLSearchParams();

      if (!disableOrdering && ordering) {
        urlQueryParams.append('ordering', ordering);
      }

      if (!disablePagination && page) {
        // Table pagination is 0-indexed
        // but Django is 1-indexed, hence page+1
        urlQueryParams.append('page', page + 1);
      }

      if (!disablePagination && pageSize) {
        urlQueryParams.append('page_size', pageSize);
      }

      if (!disableSearch && searchParams) {
        urlQueryParams.append('search', searchParams);
      }

      if (!disableComplexFilter && filterParams) {
        // TODO Change this to 'complex_filter'
        urlQueryParams.append('complex-filter', filterParams);
        if (extraQueryMethod){
            urlQueryParams.append('extra-query-method', extraQueryMethod);
        }
      }

      if (advancedSearch && bodyParams) {
        urlQueryParams.append('advanced_filter', JSON.stringify(bodyParams));
      }

      const paramString = urlQueryParams.toString();
      setQueryParams(paramString);
    }
  }, [ordering, page, pageSize, searchParams, filterParams, bodyParams]);


  const queryKey = Array.isArray(queryDef.key) ? [...queryDef.key, queryParams, bodyParams] : [queryDef.key, queryParams, bodyParams];
  const query = useQuery(
    queryKey,
    () => queryDef.func(queryParams, bodyParams), {}
  );

  // TODO Work on error handling and loading behavior
  if (query.isError) {
    console.error(query.error);
    return <GenericError error={query.error}/>;
  }

  return (
    <Scrollbar>
      <CustomizableListSearch
        filterGroups={filterGroups}
        setFilterGroups={setFilterGroups}
        disableSearch={disableSearch}
        disableComplexFilter={disableComplexFilter}
        advancedSearch={advancedSearch}
        toggleSearch={toggleSearch}
        setToggleSearch={setToggleSearch}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setBodyParams={setBodyParams}
        setFilterParams={setFilterParams}
        filterHeaders={filterHeaders}
        openCollapse={openCollapse}
        setOpenCollapse={setOpenCollapse}
        setPage={setPage} // To be able to reset page number on a search.
        bodyParams={bodyParams}
        queryParams={queryParams}
      />
      {
        (disableSearch || !filterHeaders || advancedSearch) && <Divider/>
      }
      <TableContainer
        sx={{
          maxHeight: tableMaxHeight
        }}
      >
        <Table
          sx={{
            height: "max-content",
            overflow: 'hidden'
          }}
        >
          <CustomizableListTableHeader
            tableHeaders={tableHeaders}
            ordering={ordering}
            setOrdering={setOrdering}
          />
          {
            !query.isLoading ?
              (
                <CustomizableListTableBody
                  results={query.data.results}
                  rowRenderer={customRowRenderer}
                />
              ) : (
                <SkeletonRenderer/>
              )
          }
          <CustomizableListTableFooter
            count={query.data?.count ?? 0}
            setResultCount={setResultCount}
            page={page}
            setPage={setPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
            disablePagination={disablePagination}
          />
        </Table>
      </TableContainer>
    </Scrollbar>
  );
};

const CustomizableList = (props) => {
  const [queryParams, setQueryParams] = useState('');

  // Filter Group histories
  const [filterGroupHistory, setFilterGroupHistory] = useState({});
  // Search Param histories
  const [searchHistory, setSearchHistory] = useState(null);

  return (
    <Card sx={{mt: 3}}>
      {
        props.title ? (
          <>
            <CardHeader title={props.title}/>
            <Divider/>
          </>
        ) : null
      }
      <CardContent>
        <CustomizableListBase
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          filterGroups={filterGroupHistory}
          setFilterGroups={setFilterGroupHistory}
          searchParams={searchHistory}
          setSearchParams={setSearchHistory}
          {...props}
        />
      </CardContent>
    </Card>
  );
};

export {
  CustomizableList as default,
  CustomizableListBase,
};
