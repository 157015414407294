/*
  Objects encapsulating key-function pairs for
  API calls for authentication processes.
*/

import { authAxios } from 'src/lib/axios';

export const otpIssue = {
  key: 'otp-issue',
  func: (data) => {
    return authAxios.post('/auth/otp/issue/', data,);
  }
};

export const otpRefresh = {
  key: 'otp-refresh',
  func: (data) => {
    return authAxios.post('/auth/otp/refresh/', data);
  }
};

export const serviceMasterOtpRefresh = {
  key: 'service-master-otp-refresh',
  func: () => {
    return authAxios.post('/auth/otp/service-master-otp/');
  }
};

export const tokenIssue = {
  key: 'token-issue',
  func: (data) => {
    return authAxios.post('/auth/token/issue/', data,);
  }
};

/*
  Intended to be used with react-query mutations
  but it is too much work for no benefit.

  'noExpire' option is needed to allow session expiration from this call.
  Checkout 'src/lib/axios' and 'src/context/JWTContext' to understand why.
  Comments are up-to-date as of 2021-05-26.
*/ 
export const tokenRefresh = {
  key: 'token-refresh',
  func: () => authAxios.post('/auth/token/refresh/', {}, { noExpire: false })
}

export const tokenRevoke = {
  key: 'token-revoke',
  func: () => authAxios.post('/auth/token/revoke/')
};

export const checkIn = {
  key: 'check-in',
  func: () => authAxios.post('/auth/token/check-in/')
};

export const passwordReset = {
  key: 'password-reset',
  func: (data) => authAxios.post('/auth/password/reset/', data)
};

export const passwordSet = {
  key: 'password-set',
  func: (data) => authAxios.post('/auth/password/set/', data)
};

