export const stringOperators = [
    {machineName: 'exact', humanName: 'Exact Match'},
    {machineName: 'iexact', humanName: 'Case Insensitive Match'},
    {machineName: 'contains', humanName: 'Contains'},
    {machineName: 'icontains', humanName: 'Case Insensitive Contains'},
    {machineName: 'startswith', humanName: 'Starts With'},
    {machineName: 'istartswith', humanName: 'Case Insensitive Starts With'},
    {machineName: 'endswith', humanName: 'Ends With'},
    {machineName: 'iendswith', humanName: 'Case Insensitive Ends With'},
];

export const integerOperators = [
    {machineName: 'exact',  humanName: 'Equal'},
    {machineName: 'gt',     humanName: 'Greater Than'},
    {machineName: 'gte',    humanName: 'Greater Than or Equal'},
    {machineName: 'lt',     humanName: 'Less Than'},
    {machineName: 'lte',    humanName: 'Less Than or Equal'},
    {machineName: 'in',     humanName: 'in'},
];

export const datetimeOperators = [
    {machineName: 'exact',  humanName: 'Equal'},
    {machineName: 'gt',     humanName: 'Greater Than'},
    {machineName: 'gte',    humanName: 'Greater Than or Equal'},
    {machineName: 'lt',     humanName: 'Less Than'},
    {machineName: 'lte',    humanName: 'Less Than or Equal'},
    {machineName: 'in',     humanName: 'in'},
];

export const booleanOperators = [
    {machineName: 'exact',  humanName: 'is'}
];

export const choiceOperators = [
    {machineName: 'exact',  humanName: 'is'}
];

