import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const OrderingIconButton = (props) => {
  const { fieldKey, ordering, setOrdering, visible } = props;

  // Take space when hidden
  if (!visible && ordering !== fieldKey && ordering !== `-${fieldKey}`) {
    return (
      <IconButton disabled>
        <ArrowUpwardIcon fontSize="small" style={{ visibility: 'hidden' }} />
      </IconButton>
    );
  }

  // Passive to Ascending
  if (ordering !== fieldKey && ordering !== `-${fieldKey}`) {
    return (
      <IconButton onClick={() => {
        setOrdering(fieldKey);
      }}>
        <ArrowUpwardIcon
          fontSize="small"
          color="disabled"
        />
      </IconButton>
    );
  }

  // Ascending to Descending
  if (ordering === fieldKey) {
    return (
      <IconButton onClick={() => {
        setOrdering(`-${fieldKey}`);
      }}>
        <ArrowUpwardIcon
          fontSize="small"
        />
      </IconButton>
    );
  }

  // Descending to Passive
  if (ordering === `-${fieldKey}`) {
    return (
      <IconButton onClick={() => {
        setOrdering(null);
      }}>
        <ArrowDownwardIcon
          fontSize="small"
        />
      </IconButton>
    );
  }
};

OrderingIconButton.propTypes = {
  fieldKey: PropTypes.string.isRequired,
  ordering: PropTypes.string.isRequired,
  setOrdering: PropTypes.func.isRequired,
  visible: PropTypes.bool
};

export default OrderingIconButton;
