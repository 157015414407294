import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')();

const MDMLogo = (props) => {
  /* 
    MDM Logo transformed from SVG to React Component using https://svg2jsx.com/
    --------------------------------
    Example usage:

      <MDMLogo 
        sx={{
          height: 150,
          width: 150
        }}
      />
  */
  const { color } = props;

  return (
    <LogoRoot
      width="399.975"
      height="402.533"
      version="1.1"
      viewBox="0 0 105.827 106.504"
      {...props}
    >
      <title>Token MDM</title>
      <g
        fill="#007398"
        fillOpacity="1"
        stroke="#007398"
        strokeDasharray="none"
        strokeOpacity="1"
        strokeWidth="1.72"
        transform="translate(-48.631 -3.84)"
      >
        <path d="M88.68 18.33C81.663 25.348 75.884 31.3 75.884 31.505c0 .207.378.723.86 1.17l.894.825 11.936-11.936 11.937-11.937 5.16 5.16 5.16 5.16L99.86 31.918 87.889 43.89l1.066.998 1.067.997 10.216-10.148c5.607-5.572 11.042-10.904 12.04-11.833l1.789-1.72 5.675 5.676 5.676 5.676 1.032-1.032 1.032-1.032-13.003-13.003L101.511 5.5z"></path>
        <path d="M107.187 49.702l-6.674 6.674.998 1.066.998 1.067 5.779-5.78 5.779-5.744 11.317 11.49 11.317 11.523-17.578 17.612-17.612 17.647-5.16-5.16-5.16-5.16 11.971-11.97 11.971-11.971-1.032-.964-1.032-.997-11.936 11.764c-6.536 6.433-12.006 11.73-12.143 11.73-.104 0-2.512-2.27-5.298-5.09l-5.057-5.057 6.158-6.158 6.157-6.157-6.57-6.811-6.57-6.846-1.135 1.101-1.135 1.1 5.675 5.711 5.676 5.676-5.16 5.16-5.16 5.16-11.42-11.455-11.455-11.42 5.676-5.677 5.676-5.675-1.135-1.101-1.101-1.135-6.226 6.191c-3.406 3.406-6.364 6.468-6.536 6.812-.31.55 1.823 2.82 15.067 16.167 8.462 8.531 20.158 20.192 25.937 25.937l10.491 10.389 25.8-25.765c14.172-14.173 25.8-25.937 25.868-26.075.034-.172-2.855-3.302-6.433-6.983l-6.501-6.673-1.17 1.135-1.135 1.1 5.676 5.676 5.676 5.676-5.16 5.16-5.16 5.16-12.384-12.384c-6.81-6.81-12.452-12.383-12.555-12.383-.104 0-3.2 3.027-6.88 6.707z"></path>
      </g>
    </LogoRoot>
  );
};

export default MDMLogo;
