import PropTypes from "prop-types";
import { Formik } from 'formik';
import { Button } from '@material-ui/core';

const ActionButton = (props) => {
  /*
    label: Button text
    variant: Button variant, considered 'outlined' by default
    isDisabled: Boolean to enable or disable the button, considered false by default
    action: Promise to call when the button is clicked
    onSuccess: Promise to call if the action was successful
    onError: Promise to call if an error occured during the execution of the action
    color: Color of the button
    startIcon: Icon to the left of the Button label text
    endIcon: Icon to the right of the Button label text
  */
  const { label, variant, style, isDisabled, action, onSuccess, onError, color, startIcon, endIcon } = props;

  return (
    <Formik
      initialValues={{
        hiddenValue: ''
      }}
      onSubmit={async (values, { setStatus, setSubmitting }) => {
        try {
          const result = await action();
          setStatus({ success: true });
          setSubmitting(false);
          if (onSuccess !== undefined) {
            await onSuccess(result);
          }
        } catch (error) {
          setStatus({ success: false });
          setSubmitting(false);
          if (onError !== undefined) {
            await onError(error);
          }
        }
      }}
    >
      {({
        handleSubmit,
        isSubmitting
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Button
            sx={style}
            color={color ? color : "primary"}
            variant={variant === undefined ? 'outlined' : variant}
            type="submit"
            disabled={(isDisabled === undefined ? false : isDisabled) || isSubmitting}
            startIcon={startIcon}
            endIcon={endIcon}
          >
            {label}
          </Button>
        </form>
      )}
    </Formik>
  );
};

ActionButton.propTypes = {
  label: PropTypes.node.isRequired,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  action: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

export default ActionButton;
