import { Box } from '@material-ui/core';
import { MDMLogo } from './logo';
import { experimentalStyled } from '@material-ui/core/styles';

const SplashScreen = () => {
  const RollingBreathe = experimentalStyled('div')({
    "@keyframes pulsate": {
      from: {
        opacity: 1,
        transform: "scale(1) rotate(0deg)"
      },
      to: {
        opacity: 0.2,
        transform: "scale(1.25) rotate(-45deg)"
      }
    },
    animation: "pulsate 1.5s linear infinite alternate",
    position: "absolute"
  });

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        p: 3,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000
      }}
    >
      <RollingBreathe>
        <MDMLogo 
          height='200'
          width='200'
        />
      </RollingBreathe>
    </Box>
  );
}

export default SplashScreen;
