import {
  Card, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText
} from '@material-ui/core';
import { useState } from 'react';
import { LoadingIndicator } from 'src/components/common';
import GenericError from 'src/components/GenericError';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { getModelTypeList } from 'src/api/queries';


const ModelTypeCheckboxGroup = (props) => {
  // ----------------------------------------------
  // Checkbox group component for the Model Types.
  // yieldLabels: boolean. Use `true` if you want your results array to return the labels of the model types. 
  // ----------------------------------------------
  const { disabled, checked, setChecked, error, setErrors, setFieldValue, yieldLabels } = props;
  const queryClient = useQueryClient();
  const [modelTypeSet, setModelTypeSet] = useState(new Set(checked));

  // Get model types as a list (to put them later into checkboxes)
  const query = useQuery(
    [getModelTypeList.key],
    () => getModelTypeList.func(`page_size=300`)    // Default page size = 10 might not be enough to fetch all model types.
  );
    
  if (query.isLoading) {
    return <LoadingIndicator />;
  }

  if (query.isError) {
    console.error(query.error);
    setErrors({ model_types: 'Could not retrieve model type list.' });
    return <GenericError error={query.error} />;
  }

  const toggleCheckboxValue = (item) => {
    // ----------------
    // Yields Labels of the model types instead of ids.
    // ----------------
    if(yieldLabels) {     
      const newSet = new Set(modelTypeSet);
      if (newSet.has(item.name)) {
        newSet.delete(item.name);
      }
      else {
        newSet.add(item.name);
      }
      setModelTypeSet(newSet);    // set the modelTypeSet with the new values
      const newCheckedList = Array.from(newSet);
      setChecked(newCheckedList); // forward the new values to the array.
      setFieldValue('model_types', newCheckedList);
    }
    // ----------------
    // Yields IDs of the model types.
    // ----------------
    else {
      const newSet = new Set(modelTypeSet);
      if (newSet.has(item.id)) {
        newSet.delete(item.id);
      }
      else {
        newSet.add(item.id);
      }
      setModelTypeSet(newSet);    // set the modelTypeSet with the new values
      const newCheckedList = Array.from(newSet);
      setChecked(newCheckedList); // forward the new values to the array.
      setFieldValue('model_types', newCheckedList);
    }
  };

  return (
    <Card
        sx={{ display: 'inline-block', minWidth: 500}}
      >
      <FormControl
        error={Boolean(error)}
      >
        <FormGroup
          row={false}
        >
          {query.data.results.map( (item) => (
            <FormControlLabel
              key={`checkbox-group-form-control-label-${item.id}`}
              control={
                <Checkbox sx={{ ml: 2 }}
                  key={`model-type-checkbox-${item.id}`}
                  name={item.name}
                  checked={!yieldLabels ? modelTypeSet.has(item.id) : modelTypeSet.has(item.name)}
                  onChange={() => toggleCheckboxValue(item)}
                  color="success"
                  disabled={disabled}
                />
              }
              label={item.name}
            />
          ))}
        </FormGroup>
        <FormHelperText>{error}</FormHelperText>
      </FormControl>
    </Card>
  );
}   

export default ModelTypeCheckboxGroup;