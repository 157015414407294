import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  Popover,
  Typography
} from '@material-ui/core';
import User from '../../../icons/User';
import useAuth from 'src/hooks/useAuth';

const AccountPopover = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <User />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 350 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textSecondary"
            variant="subtitle1"
            align="center"
          >
            { user?.username || 'Account' }
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Typography
            color="textSecondary"
            variant="body1"
            align="center"
          >
            { user?.email }
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              handleClose();
              navigate(`/dashboard/users/${user.id}/edit`);
            }}
            variant="outlined"
          >
            Profile
          </Button>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              handleClose();
              navigate('/authentication/logout');
            }}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
    </>
  );
};

export default AccountPopover;
