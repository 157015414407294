import {
  Autocomplete, TextField
} from "@material-ui/core";
import { useState } from "react";
import { useQuery } from "react-query";
import { getApplicationList } from "src/api/queries";
  

const ApplicationAutocomplete = (props) => {
  const { disabled, errors, required, handleBlur, initialApplication, extraQueryParams, setErrors, setFieldValue, autoSelect, touched, afterChange } = props;
  const [isFirst, setIsFirst] = useState(true);  
  const [value, setValue] = useState(initialApplication);
  const [search, setSearch] = useState('');

  // queryParams should be able to utilize extra info such as Package Type which can be inputted via RadioButton etc.
  const queryParams = extraQueryParams ? `page_size=300&visual_name__icontains=${search}&${extraQueryParams}` : `page_size=300&visual_name__icontains=${search}`;
  const query = useQuery(
    [getApplicationList.key, 'autocomplete', { params: queryParams }],
    () => getApplicationList.func(queryParams)
  );
  
  if (query.isError) {
    setErrors({ owner: 'Could not retrieve application list.' });
  }
  
  const options = isFirst && initialApplication
      ? [initialApplication]
      : (query.isFetching ? [] : query.data.results);

  return (
    <Autocomplete
      id='application-autocomplete'
      disabled={disabled || query.isError}
      loading={query.isFetching}
      loadingText='Loading applications...'
      autoSelect={autoSelect}
      onInputChange={(_, v) => {
        setSearch(v);
        setIsFirst(false);
      }}
      onChange={(_, v) => {
          setValue(v);
          setFieldValue('application', v || null);
          if (typeof afterChange === 'function') {
              afterChange(_, v); // Only call afterChange if it's a function and
                                // For changes that will be applied whenever this component's input changes.
          }
      }}
      onOpen={handleBlur}
      options={options}
      renderInput={(params) => (
        <TextField {...params}
          label='Application'
          error={Boolean(touched.application && errors.application)}
          helperText={touched.application && errors.application}
          fullWidth
          name='application'
          variant='outlined'
          required={required}
        />
      )}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option ? (`${option?.visual_name}`) : ''}
      value={value}
    />
  );
};
  
export default ApplicationAutocomplete;