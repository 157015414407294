import { Box, Drawer, Hidden } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AppRegistrationIcon from '@material-ui/icons/AppRegistration';
import AppsIcon from '@material-ui/icons/Apps';
import CreateIcon from '@material-ui/icons/Create';
import DevicesIcon from '@material-ui/icons/Devices';
import GroupsIcon from '@material-ui/icons/Groups';
import ManageAccountsIcon from '@material-ui/icons/ManageAccounts';
import PointOfSaleIcon from '@material-ui/icons/PointOfSale';
import SummarizeIcon from '@material-ui/icons/Summarize';
import TuneIcon from '@material-ui/icons/Tune';
import SimCardIcon from '@material-ui/icons/SimCard';
import Inventory2Icon from '@material-ui/icons/Inventory2';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import AssessmentIcon from '@material-ui/icons/Assessment'
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {CompanyLogoHorizontalWithTitle, MDMLogoHorizontalWithTitle} from 'src/components/logo';
import ChartSquareBarIcon from '../../../icons/ChartSquareBar';
import DeviceTabletIcon from '../../../icons/DeviceTablet';
import NavSection from '../../NavSection';
import Scrollbar from '../../Scrollbar';
import useSettings from "../../../hooks/useSettings";


const sections = [
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />
      }
    ]
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Device Management',
        path: '/dashboard/devices',
        icon: <DeviceTabletIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/devices',
            permissions: ['commonlib.view_device']
          },
          {
            title: 'Create',
            path: '/dashboard/devices/create',
            permissions: ['commonlib.add_device']
          },
          {
            title: 'Upload',
            path: '/dashboard/devices/upload',
            permissions: ['commonlib.add_device']
          },
          {
            title: 'Service Intervention',
            path: '/dashboard/devices/serviceintervention',
            permissions: ['commonlib.handle_intervention']
          }
        ]
      },
      {
        title: 'Merchant Management',
        path: '/dashboard/companies',
        icon: <PointOfSaleIcon fontSize="small" />,
        children: [
          {
            title: 'List Administrative Companies',
            path: '/dashboard/companies',
            permissions: ['commonlib.view_company']
          },
          {
            title: 'Create Administrative Company',
            path: '/dashboard/companies/create',
            permissions: ['commonlib.add_company']
          },
          {
            title: 'List Merchants',
            path: '/dashboard/merchants',
            permissions: ['commonlib.view_merchant']
          },
          {
            title: 'Create Merchant',
            path: '/dashboard/merchants/create',
            permissions: ['commonlib.add_merchant']
          },
          {
            title: 'Device Store Upload',
            path: '/dashboard/devicestores/upload',
            permissions: ['commonlib.add_devicestore']
          },
          {
            title: 'Merchant Upload',
            path: '/dashboard/merchants/upload',
            permissions: ['commonlib.add_merchant']
          }
        ]
      },
      {
        title: 'App Management',
        path: '/dashboard/apps',
        icon: <AppsIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/apps',
            permissions: ['commonlib.view_application']
          },
          {
            title: 'Create',
            path: '/dashboard/apps/create',
            permissions: ['commonlib.add_application']
          },
          {
            title: 'Bulk Assignment',
            path: '/dashboard/apps/assign',
            permissions: ['commonlib.change_deviceapplication']
          }
        ]
      },
      {
        title: 'Repository Management',
        path: '/dashboard/repositories',
        icon: <AppRegistrationIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/repositories',
            permissions: ['commonlib.view_repository']
          },
          {
            title: 'Create',
            path: '/dashboard/repositories/create',
            permissions: ['commonlib.add_repository']
          }
        ]
      },
      {
        title: 'Parameter Management',
        path: '/dashboard/parameters',
        icon: <TuneIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/parameters',
            permissions: ['commonlib.view_parameter']
          },
          {
            title: 'Create',
            path: '/dashboard/parameters/create',
            permissions: ['commonlib.add_parameter']
          }
        ]
      },
      {
        title: 'Model Management',
        path: '/dashboard/modeltypes',
        icon: <DevicesIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/modeltypes',
            permissions: ['commonlib.view_modeltype']
          },
          {
            title: 'Create',
            path: '/dashboard/modeltypes/create',
            permissions: ['commonlib.add_modeltype']
          }
        ]
      },
      {
        title: 'User Management',
        path: '/dashboard/users',
        icon: <ManageAccountsIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/users',
            permissions: ['commonlib.view_user']
          },
          {
            title: 'Create',
            path: '/dashboard/users/create',
            permissions: ['commonlib.add_user']
          }
        ]
      },
      {
        title: 'Role Management',
        path: '/dashboard/roles',
        icon: <GroupsIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/roles',
            permissions: ['auth.view_group']
          },
          {
            title: 'Create',
            path: '/dashboard/roles/create',
            permissions: ['auth.add_group']
          }
        ]
      },
      {
        title: 'Sim Management',
        path: '/dashboard/sim',
        icon: <SimCardIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/sim',
            permissions: ['commonlib.view_simstock']
          },
          {
            title: 'Create',
            path: '/dashboard/sim/create',
            permissions: ['commonlib.add_simstock']
          },
          {
            title: 'Upload',
            path: '/dashboard/sim/upload',
            permissions: ['commonlib.add_simstock']
          }
        ]
      },
      {
        title: 'Report Management',
        path: '/dashboard/reports',
        icon: <AssessmentIcon fontSize="small" />,
        children: [
          {
            title: 'Incident Report',
            path: '/dashboard/reports/',
            permissions: ['commonlib.view_incident']
          },
          {
            title: 'SLA Report',
            path: '/dashboard/reports/sla-reports',
            permissions: ['commonlib.view_incident']
          },
          {
            title: 'Invoicing Report',
            path: '/dashboard/reports/invoicing-reports',
            permissions: ['commonlib.view_incident']
          },
        ]
      },
      {
        title: 'Incident Management',
        path: '/dashboard/incidents',
        icon: <SummarizeIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/incidents',
            permissions: ['commonlib.view_incident']
          },
          {
            title: 'Create',
            path: '/dashboard/incidents/create',
            permissions: ['commonlib.add_incident']
          },
          {
            title: 'Incident Type Management',
            path: '/dashboard/incidenttype',
            icon: <SummarizeIcon fontSize="small" />,
            children: [
              {
                title: 'List',
                path: '/dashboard/incidenttype',
                permissions: ['commonlib.view_incidenttype']
              },
              {
                title: 'Create',
                path: '/dashboard/incidenttype/create',
                permissions: ['commonlib.add_incidenttype']
              },
            ]
          },
        ]
      },
      {
        title: 'SLA Time Management',
        path: '/dashboard/companySLAtime',
        icon: <TimelapseIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/companySLAtime',
            permissions: ['commonlib.view_companyslatime']
          },
          {
            title: 'Create',
            path: '/dashboard/companySLAtime/create',
            permissions: ['commonlib.add_companyslatime']
          }
        ]
      },
      // TODO: NOT BEING USED FOR NOW. TO BE UNCOMMENTED LATER.
      // {
      //   title: 'Tag Management',
      //   path: '/dashboard/tags',
      //   icon: <LocalOfferIcon fontSize="small" />,
      //   children: [
      //     {
      //       title: 'List',
      //       path: '/dashboard/tags',
      //       permissions: ['commonlib.view_tag']
      //     },
      //     {
      //       title: 'Create',
      //       path: '/dashboard/tags/create',
      //       permissions: ['commonlib.add_tag']
      //     }
      //   ]
      // },
      {
        title: 'Logs',
        path: '/dashboard/logs',
        icon: <CreateIcon fontSize="small" />,
        children: [
          {
            title: 'Device Application Logs',
            path: '/dashboard/logs/deviceapplicationlog',
            permissions: ['commonlib.view_deviceapplicationlog']
          },
          {
            title: 'Device Parameter Logs',
            path: '/dashboard/logs/deviceparameterlog',
            permissions: ['commonlib.view_deviceparameterlog']
          },
          {
            title: 'User Logs',
            path: '/dashboard/logs/userlog',
            permissions: ['commonlib.view_userlog']
          }
        ]
      },
      {
        title: 'Banks',
        path: '/dashboard/banks',
        icon: <AccountBalanceIcon fontSize="small" />,
        children: [
          {
            title: 'List',
            path: '/dashboard/banks',
            permissions: ['commonlib.view_bank']
          },
          {
            title: 'Create',
            path: '/dashboard/banks/create',
            permissions: ['commonlib.add_bank']
          },
          {
            title: 'Bank Device Records',
            path: '/dashboard/bankdevices',
            permissions: ['commonlib.view_bankdevice', 'commonlib.view_bankdeviceregistry'],
            children: [
              {
                title: 'List',
                path: '/dashboard/bankdevices',
                permissions: ['commonlib.view_bankdevice', 'commonlib.view_bankdeviceregistry']
              },
              {
                title: 'Create',
                path: '/dashboard/bankdevices/create',
                permissions: ['commonlib.add_bankdevice', 'commonlib.add_bankdeviceregistry']
              }
            ]
          },

        ]
      },//,
      // {
      //   title: 'Location Services',
      //   path: '/dashboard/location_services',
      //   icon: <Map fontSize="small" />,
      //   children: [
      //     {
      //       title: 'Location Detail',
      //       path: '/dashboard/location_services'
      //     }
      //   ]
      // }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const theme = useTheme();
  const { settings } = useSettings();


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 2
            }}
          >
            <RouterLink to="/" style={{'textDecoration': 'none'}}>
              {settings.companyName !== "TOKEN MDM" ? (
                  <CompanyLogoHorizontalWithTitle
                      color={settings.theme === "LIGHT" ? settings.themeColor:"#ffffff"}
                      companyName={settings.companyName}
                      logoUrl={settings.logo}
                      sx={{
                        mt: 1,
                        height: 50,
                        width: 240
                      }}
                  />
              ) : (
                  <MDMLogoHorizontalWithTitle
                      color={theme.palette.primary.contrastText}
                      sx={{
                        mt: 1,
                        height: 50,
                        width: 240
                      }}
                  />)}
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
