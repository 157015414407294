import {
  Dialog,
  DialogActions,
  DialogTitle
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation, useQueryClient } from "react-query";
import { deleteModelType } from "src/api/mutations";
import {
  getModelTypeList
} from "src/api/queries";
import {
  ActionButton
} from "src/components/common";


const DeleteModelTypeDialog = ({modelType, navigate, open, setOpen}) => {
  const queryClient = useQueryClient();
  const {enqueueSnackbar} = useSnackbar();
  const mutation = useMutation(
    () => deleteModelType.func(modelType.id),
    {
      mutationKey: deleteModelType.key,
      onSuccess: () => queryClient.invalidateQueries(getModelTypeList.key)
    }
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{`Delete "${modelType.name}"?`}</DialogTitle>
      <DialogActions>
        <ActionButton
          label='Cancel'
          variant='outlined'
          action={() => setOpen(false)}
        />
        <ActionButton
          label='Delete'
          color='error'
          action={() => {
            mutation.mutate({}, {
              onSuccess: () => {
                navigate('/dashboard/modeltypes')
                enqueueSnackbar('Model Type Deleted Successfully',
                {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'success'
                }
              );
              },
              onError: () => {
                enqueueSnackbar('Model Type Deletion Failed',
                {
                  anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                  },
                  variant: 'error'
                }
              );
              }
            });
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModelTypeDialog;
