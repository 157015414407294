import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')();

const MDMLogoRectangleWithTitle = (props) => (
  /* 
    MDM Logo Rectangle with `Token MDM` title.
    Transformed from SVG to React Component using https://svg2jsx.com/
    --------------------------------
    Example usage:

      <MDMLogoRectangleWithTitle 
        sx={{
          height: 200
        }}
      />
  */
  <LogoRoot
    width="1449.333"
    height="649.333"
    preserveAspectRatio="xMidYMid meet"
    version="1"
    viewBox="0 0 1087 487"
    {...props}
  >
    <title>Token MDM</title>
    <g
        fill="#007398"
        fillOpacity="1"
        stroke="none"
        transform="matrix(.1 0 0 -.1 0 487)"
      >
        <path
          stroke="#007398"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="50"
          d="M2432 3697c-204-204-372-377-372-383s11-21 25-34l26-24 347 347 347 347 150-150 150-150-348-348-348-348 31-29 31-29 297 295c163 162 321 317 350 344l52 50 165-165 165-165 30 30 30 30-378 378-377 377-373-373z"
        ></path>
        <path d="M4860 3770c-22-22-25-42-10-71 10-17 22-19 140-19h129l3-381c3-363 4-381 22-395 27-19 52-18 76 6 19 19 20 33 20 395v375h130c118 0 130 2 140 19 16 31 12 59-12 75-19 14-67 16-320 16-285 0-299-1-318-20z"></path>
        <path d="M5971 3779c-129-25-257-125-312-243-154-329 115-694 471-639 97 15 173 55 246 128 68 67 97 116 119 202 83 319-198 616-524 552zm214-130c71-27 147-100 183-176 21-46 26-73 27-133 0-66-5-85-33-142-103-209-367-258-530-98-69 68-96 127-100 222-6 114 15 167 97 249 44 44 81 71 111 82 66 23 177 21 245-4z"></path>
        <path d="M6748 3779c-17-9-18-41-18-439 0-414 1-430 19-440 29-15 49-12 71 10 18 18 20 33 20 168v148l38 37c21 20 41 37 44 37 4 0 73-90 154-200 96-130 155-202 170-206 48-12 86 35 63 78-7 13-74 106-149 208s-140 191-144 199c-6 9 33 55 137 160 176 177 186 192 147 231s-55 29-262-177l-198-197v177c0 164-2 179-20 197-22 22-46 25-72 9z"></path>
        <path d="M7540 3770c-19-19-20-33-20-430s1-411 20-430 33-20 300-20 281 1 300 20c24 24 26 53 4 74-13 14-51 16-260 16h-244v280h175c162 0 177 2 195 20 24 24 25 49 6 76-13 17-30 19-195 22l-181 3v279h250c236 0 250 1 260 19 15 29 12 49-10 71-19 19-33 20-300 20s-281-1-300-20z"></path>
        <path d="M8387 3772c-15-17-17-62-17-435 0-364 2-418 16-431 21-21 57-20 77 2 15 16 17 54 17 331 0 188 4 310 9 306 5-3 121-148 257-323 136-174 252-320 258-324 20-13 53-8 69 10 15 17 17 62 17 435v416l-22 15c-29 21-51 20-71-2-15-16-17-54-17-330 0-172-4-312-8-312-5 0-122 146-260 324s-259 327-268 330c-26 10-40 7-57-12z"></path>
        <path
          stroke="#007398"
          strokeDasharray="none"
          strokeOpacity="1"
          strokeWidth="50"
          d="M2970 2785l-194-194 29-31 29-31 168 168 168 167 329-334 329-335-511-512-512-513-150 150-150 150 348 348 348 348-30 28-30 29-347-342c-190-187-349-341-353-341-3 0-73 66-154 148l-147 147 179 179 179 179-191 198-191 199-33-32-33-32 165-166 165-165-150-150-150-150-332 333-333 332 165 165 165 165-33 32-32 33-181-180c-99-99-185-188-190-198-9-16 53-82 438-470 246-248 586-587 754-754l305-302 750 749c412 412 750 754 752 758 1 5-83 96-187 203l-189 194-34-33-33-32 165-165 165-165-150-150-150-150-360 360c-198 198-362 360-365 360s-93-88-200-195z"
        ></path>
        <path d="M4884 2638c-12-5-27-21-33-34-8-18-11-204-11-620v-596l29-29c36-36 70-37 108-5l28 24 5 408 5 407 215-424c198-390 217-424 245-432 69-19 67-22 297 435l213 423 2-400c2-271 6-407 14-421 12-24 49-44 80-44 11 0 34 13 50 29l29 29v1204l-29 29c-16 16-39 29-50 29-60 0-62-3-324-527-139-277-254-503-257-503s-119 227-258 504c-259 516-266 527-320 525-9 0-27-5-38-11z"></path>
        <path d="M6515 2639c-45-28-44-14-45-647v-604l31-30 30-31 202 6c221 6 261 13 377 72 162 83 290 242 337 418 23 85 22 262-1 340-65 222-233 392-456 462-71 23-443 33-475 14zm408-174c87-23 155-61 224-128 196-188 202-485 13-681-108-113-222-156-412-156h-108v980h113c68 0 136-6 170-15z"></path>
        <path d="M7803 2626l-28-24-2-599c-2-466 0-603 10-623 26-51 83-62 132-26 19 14 20 29 25 428l5 413 212-421c184-365 216-423 241-433 33-14 74-7 95 17 8 10 107 202 220 427l206 410v-403l1-404 29-29c38-38 74-38 112 0l29 29v604c0 593 0 605-20 626-28 30-68 38-99 21-20-11-84-130-277-514-138-274-255-500-260-502s-123 224-262 502c-263 526-262 525-322 525-10 0-32-11-47-24z"></path>
      </g>
  </LogoRoot>
);

export default MDMLogoRectangleWithTitle;
