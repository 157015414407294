import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled, useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { MDMLogoHorizontalWithTitle, CompanyLogoHorizontalWithTitle } from 'src/components/logo';
import SettingsDrawer from 'src/components/SettingsDrawer';
import MenuIcon from '../../../icons/Menu';
import AccountPopover from './AccountPopover';
import SystemInfoPopover from "./SystemInfoPopover";
import useSettings from "../../../hooks/useSettings";


const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const theme = useTheme();
  const { settings } = useSettings();


  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/" style={{'textDecoration':'none'}}>
            <Box marginRight={2}>
              {settings.companyName !== "TOKEN MDM" ? (
                  <CompanyLogoHorizontalWithTitle
                      color={"#ffffff"}
                      companyName={settings.companyName}
                      logoUrl={settings.logo}
                      sx={{
                        mt: 1,
                        height: 50,
                        width: 240
                      }}
                  />
              ) : (
                  <MDMLogoHorizontalWithTitle
                      color={theme.palette.primary.contrastText}
                      sx={{
                        mt: 1,
                        height: 50,
                        width: 240
                      }}
                  />)}
            </Box>
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ mr: 4 }}>
          <SystemInfoPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <AccountPopover />
        </Box>
        <Box sx={{ ml: 1 }}>
          <SettingsDrawer />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
