import GenericError from "src/components/GenericError";

/* Simulates an Axios object to display an error using the GenericError component */
const createGenericError = ({ message, code, title }) => {
  const error = {
    response: {
      data: {
        errors: [
          {
            message: message,
            code: code,
            title: title
          }
        ]
      }
    }
  };

  return <GenericError error={error} />;
};

export default createGenericError;
