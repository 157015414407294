import { TableFooter, TablePagination, TableRow } from "@material-ui/core";


const CustomizableListTableFooter = ({
  count,
  page,
  setPage,
  pageSize,
  setPageSize,
  disablePagination,
  setResultCount
}) => {
  if (setResultCount) {
    setResultCount(count);
  }
  if (disablePagination) {
    return null;
  }
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          count={count}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          rowsPerPage={pageSize}
          onRowsPerPageChange={(e) => {
            setPageSize(parseInt(e.target.value, 10));
            setPage(0);
          }}
          rowsPerPageOptions={[5, 10, 20, 50]}
        />
      </TableRow>
    </TableFooter>
  );
};

export default CustomizableListTableFooter;
