/*
  Idea Draft:
    1) Accept multiple types of queries and items (e.g. repository and application)
    2) Be able to handle one or both of the values passed in (e.g. calling from repository detail page, repository is locked in)
    3) Have a list and an item for each item type
    4) Each type is editable, a representation and a button exists for each item type
    5) When the button is clicked for an item, another modal pops up and renders whatever selection component was passed in
    6) There may be a default selection
    7) Finally there is an ActionButton, when clicked calls a function passed by the user
*/

import { Box, Divider, Grid, Modal, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useReducer } from 'react';
import { ActionButton } from '.';
import DoneIcon from '@material-ui/icons/Done';
import SearchIcon from '@material-ui/icons/Search';

const stateInitializer = (headers) => {
  const initState = {};
  headers.forEach(header => {
    initState[header.name] = header.initialValue;
  });
  return initState;
};

const stateReducer = (state, action) => {
  const {type, name, value} = action;

  // Value is supposed to be 'headers' from props here
  if (type === 'reset') {
    return stateInitializer(value);
  }

  const {...newState} = state;
  newState[name] = value;
  return newState;
};

const modalsInitializer = (headers) => {
  const initModals = {};
  headers.forEach(header => {
    initModals[header.name] = false;
  });
  return initModals;
};

const modalsReducer = (state, action) => {
  const {type, name, value} = action;

  // Value is supposed to be 'headers' from props here
  if (type === 'reset') {
    return modalsInitializer(value);
  }

  const {...newState} = state;
  newState[name] = value;
  return newState;
};

const MultipleSelectionModal = (props) => {
  const {open, notifyClose, headers, title, subtitle, onSubmit, onSuccess, onError} = props;
  // Values for each header type
  const [state, dispatch] = useReducer(stateReducer, headers, stateInitializer);
  // Open-Close for nested modals
  const [modals, dispatchModals] = useReducer(modalsReducer, headers, modalsInitializer);
  // Only enable submit if all headers have been filled
  const enableSubmit = Object.values(state).every(Boolean);

  return (
    <Modal
      open={open}
      onClose={notifyClose}
    >
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          border: '1px solid #555',
          boxShadow: 24,
          pt: 3,
          px: 4,
          pb: 3,
        }}
      >
        <Typography variant="h5" sx={{my:1}}>
          {title} 
        </Typography>
        <Divider />
        <Typography variant="subtitle1" sx={{my:1}}>
          {subtitle} 
        </Typography>
        <Divider />
        {
          headers
            .filter(header => header?.toChange !== undefined)
            .map(header => (
              <Box key={header.name}>
                <Modal
                  open={modals[header.name]}
                  onClose={() => dispatchModals({name: header.name, value: false})}
                >
                  {
                    header.toChange({
                      setValue: (val) => dispatch({name: header.name, value: val}),
                      notifyClose: () => dispatchModals({name: header.name, value: false})
                    })
                  }
                </Modal>
              </Box>
          ))
        }
        <Grid
          container
          spacing={1}
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            marginTop: 1,
            mx: 2,
            paddingRight: 2
          }}
        >
          {
            headers.map(header => (
              <Grid
                key={header.name}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  marginTop: 1,
                  paddingRight: 2
                }}
              >
                <Grid item xs={2}>
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'capitalize'
                    }}
                  >
                    {header.name}:
                  </Typography>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'left',
                      alignItems: 'center',
                      mx: 2
                    }}
                  >
                    {header.valueToRepresentation(state[header.name])}
                  </Box>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={2}>
                  { header.allowChange && 
                      <ActionButton
                        label='Select'
                        isDisabled={!header.allowChange}
                        action={() => dispatchModals({name: header.name, value: true})}
                        startIcon={<SearchIcon />}
                      />
                  }
                </Grid>
              </Grid>
            ))
          }
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            sx={{
              marginTop: 3,
              marginBottom: 1,
              paddingRight: 3
            }}
          >
            <Grid item xs={2}>
              <ActionButton
                label='Submit'
                variant='contained'
                isDisabled={!enableSubmit}
                action={() => onSubmit(state)}
                onSuccess={async (result) => {
                  if (onSuccess !== undefined) {
                    await onSuccess(result);
                  }
                  dispatch({type: 'reset', value: headers});
                  dispatchModals({type: 'reset', value: headers});
                }}
                onError={onError}
                endIcon={<DoneIcon />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
};

/*
  open: Boolean **Determines if the Modal should be open or closed**
  notifyClose: Function **Callback to notify the parent component that the Modal should be closed**
  headers: **List of item types and required information to handle them**
    name: String **Name for this item type, used to identify this value as a (key, value) pair for the `onSubmit` function**
    initialValue: Optional[Object] **If this header has an initial value, pass it here**
    valueToRepresentation: (value: Optional[Object]) -> Node **Value renderer for the item list, must handle undefined input**
    allowChange: Boolean **If false or undefined, lock the value to `initialValue`; otherwise allow it to be changed**
    toChange: Optional[(setValue: Function) -> Node] **Renders a node that uses `setValue` to change the item value; if `allowChange` is falsy, `toChange` is not rendered or called**
  onSubmit: (input: Object) -> Optional[Object] **Takes values as (key, value) mappings and sends a request using them, passed to an `ActionButton`**
  onSuccess: (obj: Optional[Object]) -> Any **Passed to the `ActionButton`**
  onError: (err: Object) -> Any **Passed to the `ActionButton`**
*/
MultipleSelectionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  notifyClose: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    initialValue: PropTypes.object,
    valueToRepresentation: PropTypes.func.isRequired,
    allowChange: PropTypes.bool,
    toChange: PropTypes.node
  })).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func
};

export default MultipleSelectionModal;
