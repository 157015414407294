import { useTheme } from '@emotion/react';
import { Box, Button, Dialog, DialogContent, DialogContentText, DialogTitle, FormHelperText, TextField, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import * as Yup from 'yup';
import useAuth from '../../hooks/useAuth';

const LoginModal = ({
  loginOtpRefresh,
  loginTokenIssue,
  modalOpen,
  setModalOpen,
  otpToken,
  otpKey,
  setOtpKey,
}) => {
  const refreshInterval = 60;  // seconds
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    if (modalOpen) {
      setTimer(refreshInterval);
    } else {
      setTimer(0);
    }
  }, [modalOpen]);

  useEffect(() => {
    let interval = null;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer => timer - 1);  
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  return (
    <Dialog
      open={modalOpen}
      onClose={() => {}}
    >
      <DialogTitle>Two-Factor Authentication</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please submit the code sent to your email with key <Typography display="inline" variant="h6">{otpKey}</Typography>.
        </DialogContentText>
        <Formik
          initialValues={{ otp: null }}
          validationSchema={
            Yup
            .object()
            .shape({
              otp: Yup.number().typeError('Please enter a number.').required('You must enter the one time passcode.')
            })
          }
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const data = {
              'token_otp': otpToken,
              'otp': parseInt(values.otp, 10)
            };
            await loginTokenIssue.mutate(data, {
              onSuccess: (data, variables, context) => {
                setStatus({ success: true });
                setSubmitting(false);
                setModalOpen(false);
              },
              onError: (error, variables, context) => {
                setStatus({ success: false });
                setErrors({ submit: Object.values(error.response.data.errors[0].message) });
                setSubmitting(false);
              }
            });
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, setErrors, setSubmitting, touched, values }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
            >
              <Box
                sx={{
                  mx: 2,
                  my: 1,
                  display: 'flex',
                  alignItems: 'baseline',
                  alignContent: 'center',
                  justifyContent: 'space-around',
                  gap: 1
                }}
              >
                <TextField
                  autoFocus
                  error={Boolean(touched.otp && errors.otp)}
                  fullWidth
                  helperText={touched.otp && errors.otp}
                  label="OTP"
                  margin="normal"
                  name="otp"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.otp}
                  variant="outlined"
                />
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  size="large"
                  type="button"
                  variant="contained"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </Button>
              </Box>
              {errors.submit && (
                <Box sx={{ margin: 1 }}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  my: 1
                }}
              >
                <Button
                  color="info"
                  disabled={isSubmitting || timer > 0}
                  startIcon={<Refresh />}
                  type="button"
                  variant="contained"
                  onClick={() => {
                    const data = { token_otp: otpToken };
                    setSubmitting(true);
                    loginOtpRefresh.mutate(data, {
                      onSuccess: (data, variables, context) => {
                        setOtpKey(data.data.content.otp_key);
                        setSubmitting(false);
                        setErrors({ refresh: null });
                        setTimer(refreshInterval);
                      },
                      onError: (error, variables, context) => {
                        setErrors({ refresh: Object.values(error.response.data.errors[0].message) });
                        setSubmitting(false);
                      }
                    })
                  }}
                >
                  {
                    timer > 0
                    ? `Resend in ${timer} seconds...`
                    : `Resend`
                  }
                </Button>
              </Box>
              {errors.refresh && (
                <Box sx={{ margin: 1 }}>
                  <FormHelperText error>
                    {errors.refresh}
                  </FormHelperText>
                </Box>
              )}
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

const LoginJWT = (props) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { loginOtpIssue, loginOtpRefresh, loginTokenIssue, hasExpired } = useAuth();
  const recaptchaRef = useRef();
  const [modalOpen, setModalOpen] = useState(false);
  const [otpToken, setOtpToken] = useState(null);
  const [otpKey, setOtpKey] = useState(null);


  if (hasExpired && recaptchaRef.current == null) {
    enqueueSnackbar('Your session has expired, please login again.', {
      variant: 'info',
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'top'
      },
    });
  }

  return (
    <>
      <LoginModal 
        loginOtpRefresh={loginOtpRefresh}
        loginTokenIssue={loginTokenIssue}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        otpToken={otpToken}
        otpKey={otpKey}
        setOtpKey={setOtpKey}
      />
      <Formik
        initialValues={{
          username: '',
          password: '',
          submit: null
        }}
        validationSchema={Yup
          .object()
          .shape({
            username: Yup
              .string()
              .max(255)
              .required('Username is required'),
            password: Yup
              .string()
              .max(255)
              .required('Password is required')
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          const recaptchaToken = recaptchaRef.current.getValue();
          if (!recaptchaToken) {
            setStatus({ success: false });
            setErrors({ submit: 'You must pass the captcha. '});
            setSubmitting(false);
          }
          else {
            values.recaptcha_token = recaptchaToken;
            values.type = 'web';
            await loginOtpIssue.mutate(values, {
              onSuccess: (data, variables, context) => {
                setStatus({ success: true });
                setSubmitting(false);
                setOtpKey(data.data.content.otp_key);
                setOtpToken(data.data.content.token_otp);
                setModalOpen(true);
              },
              onError: (error, variables, context) => {
                setStatus({ success: false });
                setErrors({ submit: error.response.data.errors[0].message });
                setSubmitting(false);
              }
            });
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            <TextField
              autoFocus
              error={Boolean(touched.username && errors.username)}
              fullWidth
              helperText={touched.username && errors.username}
              label="Username"
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.username}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 1
              }}
            >
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                theme={theme.palette.mode}
              />
            </Box>
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting || loginOtpIssue.isLoading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                {loginOtpIssue.isLoading ? "Logging in..." : "Log In"}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LoginJWT;
