import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Container,
  InputAdornment,
  TextField
} from '@material-ui/core';
import SearchIcon from 'src/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const SimpleSearchBar = ({
  searchParams,
  setSearchParams,
  setPage,
}) => {
  const [searchString, setSearchString] = useState(searchParams);

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setPage(0);
    setSearchParams(searchString);
  };

  const handleClear = () => {
    setSearchString('');
    setSearchParams('');
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  color="primary"
                  onClick={handleClear}
                  size="large"
                  sx={{ ml: 2 }}
                >
                  <ClearIcon fontSize="small" />
                </Button>
              </InputAdornment>
            )
          }}
          onChange={(event) => setSearchString(event.target.value)}
          onKeyUp={handleKeyUp}
          placeholder="Search..."
          value={searchString}
        />
        <Button
          color="primary"
          onClick={handleSearch}
          size="large"
          sx={{ ml: 2 }}
          variant="contained"
        >
          Search
        </Button>
      </Box>
    </Container>
  );
};

SimpleSearchBar.propTypes = {
  searchParams: PropTypes.string.isRequired,
  setSearchParams: PropTypes.func.isRequired
};

export default SimpleSearchBar;
