import PropTypes from "prop-types";
import {createContext, useEffect, useState} from "react";
import {ActionButton} from "src/components/common";


const ComplexFilterContext = createContext({
  filterHeaders: {},
  filterGroups: {},
  getUniqueGroupId: () => {
  },
  getUniqueFilterId: () => {
  },
  createFilterGroup: () => {
  },
  setFilterGroup: (groupId, filters) => {
  },
  removeFilterGroup: (groupId) => {
  },
  createFilter: () => {
  },
  setFilter: (groupId, filterId, obj) => {
  },
  removeFilter: (groupId, filterId) => {
  },
});

const ComplexFilterContextProvider = (props) => {
  const {children, filterHeaders, setFilterQueryParams, initialFilterGroups,} = props;
  const [groupCounter, setGroupCounter] = useState(1);
  const [filterCounter, setFilterCounter] = useState(1);
  const [filterGroups, setFilterGroups] = useState({});

  /*
    1) filterGroups is a map that takes groupIds as keys.
    2) For each group in filterGroups, the value format is as follows:
      {
        groupId: *value of groupId*,
        filters: *map that takes filterIds as keys*
      }
    3) For each filter in a group, the value format is as follows:
      {
        filterId: *ID of the filter*,
        active: *boolean for if the filter is active or not*,
        column: *anything related to the model being filtered, does not have to be an actual column on the model's DB table*,
        operator: *type of the filtering operation*,
        value: *value passed to the filter operator*,
        type: *boolean: if false the filter, negation of the filter is used*
      }
      Note: Names here are kept for historical compatibility and do not match their exact meaning.
  */

  useEffect(() => {
    const params = [];
    for (const group of Object.values(filterGroups)) {
      const curr = [];
      for (const filter of Object.values(group.filters)) {
        if (filter.active && filter.value) {
          curr.push({
            c: filter.column,
            o: filter.operator,
            v: filter.value,
            n: filter.type ? 0 : 1
          });
        }
      }
      params.push(curr);
    }
    setFilterQueryParams(JSON.stringify(params));
  }, [filterGroups]);

  useEffect(() => {
    if (initialFilterGroups && Object.keys(initialFilterGroups).length > 0) {
      setFilterGroups(initialFilterGroups)
      console.log(Object.values(initialFilterGroups));
      let maxGroup = 0;
      let maxFilter = 0;
      maxGroup = Math.max(...Object.keys(initialFilterGroups).map(o => parseInt(o)))
      for (const group of Object.values(initialFilterGroups)) {
        let tempMax = Math.max(...Object.keys(group.filters).map(o => parseInt(o)))
        maxFilter = Math.max(maxFilter, tempMax)
      }
      console.log("max group " + maxGroup)
      console.log("max filter " + maxFilter)

      setGroupCounter(current => maxGroup + 1)
      setFilterCounter(current => maxFilter + 1)
    } else {
      const group = createFilterGroup();
      const filter = createFilter();
      group.filters[filter.filterId] = filter;
      setFilterGroup(group.groupId, group);
    }
  }, []);

  const getUniqueGroupId = () => {
    const current = groupCounter;
    setGroupCounter(current => current + 1);
    return current;
  };

  const getUniqueFilterId = () => {
    const current = filterCounter;
    setFilterCounter(current => current + 1);
    return current;
  };

  const createFilterGroup = () => {
    const groupId = getUniqueGroupId();
    const group = {
      groupId: groupId,
      filters: {}
    };
    return group;
  };

  const setFilterGroup = (groupId, obj) => {
    const newGroups = {...filterGroups};
    newGroups[groupId] = obj;
    setFilterGroups(newGroups);
  };

  const removeFilterGroup = (groupId) => {
    const newGroups = Object.keys(filterGroups)
      .filter(key => key != groupId)
      .reduce((obj, key) => {
          obj[key] = filterGroups[key];
          return obj;
        }, {}
      );

    // No group left
    if (Object.keys(newGroups).length === 0) {
      const newGroup = createFilterGroup();
      const newFilter = createFilter();
      newGroup.filters[newFilter.filterId] = newFilter;
      newGroups[newGroup.groupId] = newGroup;
    }

    setFilterGroups(newGroups);
  };

  const createFilter = () => {
    const filterId = getUniqueFilterId();
    const filter = {
      filterId: filterId,
      active: true,
      column: filterHeaders[0].machineName,
      operator: filterHeaders[0].operators[0].machineName,
      value: undefined,
      type: true,
    };
    return filter;
  };

  const setFilter = (groupId, filterId, obj) => {
    const newGroups = {...filterGroups};
    newGroups[groupId] = filterGroups[groupId];
    newGroups[groupId].filters[filterId] = obj;
    setFilterGroups(newGroups);
  };

  const removeFilter = (groupId, filterId) => {
    // Copy old filterGroups except for the group with the given `groupId`
    const newGroups = Object.keys(filterGroups)
      .filter(key => key != groupId)
      .reduce((obj, key) => {
          obj[key] = filterGroups[key];
          return obj;
        }, {}
      );
    // Copy that group's filters except for the filter with the given `filterId`
    newGroups[groupId] = {groupId};
    newGroups[groupId].filters = Object.keys(filterGroups[groupId].filters)
      .filter(key => key != filterId)
      .reduce((obj, key) => {
          obj[key] = filterGroups[groupId].filters[key];
          return obj;
        }, {}
      );

    // No filter left in the group
    if (Object.keys(newGroups[groupId].filters).length === 0) {
      const newFilter = createFilter();
      newGroups[groupId].filters[newFilter.filterId] = newFilter;
    }

    setFilterGroups(newGroups);
  };

  return (
    <ComplexFilterContext.Provider
      value={{
        filterHeaders,
        filterGroups,
        createFilterGroup,
        setFilterGroup,
        removeFilterGroup,
        createFilter,
        setFilter,
        removeFilter,
      }}
    >
      {children}
    </ComplexFilterContext.Provider>
  );
};

ComplexFilterContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  filterHeaders: PropTypes.arrayOf(
    PropTypes.exact({
      machineName: PropTypes.string,
      humanName: PropTypes.string,
      inputType: PropTypes.string,
      operators: PropTypes.arrayOf(
        PropTypes.exact({
          machineName: PropTypes.string,
          humanName: PropTypes.string
        })
      ),
      choices: PropTypes.array
    })
  ).isRequired,
  setFilterQueryParams: PropTypes.func.isRequired
};

export {
  ComplexFilterContextProvider as default,
  ComplexFilterContext
};
