import {
  TableRow,
  TableCell,
  Button,
} from '@material-ui/core';

import {AddBox, Delete, } from '@material-ui/icons';

const AdvancedFilterGroupFooter = ({
  filterGroup_id,
  dispatchFilterGroups,
  headers
}) => (
  <TableRow>
    <TableCell
      colSpan={6}
      align="center"   
    >
      <Button
      variant="outlined"
      startIcon={<AddBox />}
      onClick={() => dispatchFilterGroups({type: "ADD_FILTER", id: filterGroup_id, headers: headers})}
      >
        Add Filter
      </Button>

      <Button
      variant="outlined"
      startIcon={<Delete />}
      color="secondary"
      onClick={() => dispatchFilterGroups({type: "DELETE_FILTER_GROUP", id: filterGroup_id})}
      >
        Delete Group
      </Button>

    </TableCell>
  </TableRow> 
)

export default AdvancedFilterGroupFooter;