import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { List, ListSubheader } from '@material-ui/core';
import NavItem from './NavItem';
import PermissionGate from 'src/permission-management/PermissionGate';
import useAuth from "../hooks/useAuth";

const extractSectionPermissions = (items) => {
  const permissions = new Set();
  let overrideCheck = false;

  // Gather permissions from lower levels
  // If there exists an element without permission requirements
  // Turn override to true, we must render the NavSection
  // Each NavItem will have its own checks anyway
  items.forEach(item => {
    // Section with children
    if (item.children && Array.isArray(item.children) && item.children.length > 0) {
      item.children.forEach(child => {
        if (child.permissions && Array.isArray(child.permissions)) {
          if (child.permissions.length === 0) {
            overrideCheck = true;
          }
          child.permissions.forEach(p => permissions.add(p));
        }
      });
    }
    // Section without children with permissions
    else if (item.permissions && Array.isArray(item.permissions) && item.permissions.length > 0) {
      item.permissions.forEach(p => permissions.add(p));
    }
    // Section without children without permissions
    else {
      overrideCheck = true;
    }
  });

  return [Array.from(permissions), overrideCheck];
};

const extractItemPermissions = (item) => {
  const permissions = new Set();
  let overrideCheck = false;

  // Item with children
  if (item.children && Array.isArray(item.children) && item.children.length > 0) {
    item.children.forEach(child => {
      if (child.permissions && Array.isArray(child.permissions)) {
        if (child.permissions.length === 0) {
          overrideCheck = true;
        }
        child.permissions.forEach(p => permissions.add(p));
      }
    });
  }
  // Item without children with permissions
  else if (item.permissions && Array.isArray(item.permissions)) {
    if (item.permissions.length === 0) {
      overrideCheck = true;
    }
    item.permissions.forEach(p => permissions.add(p));
  }
  // Item without children without permissions
  else {
    overrideCheck = true;
  }

  return [Array.from(permissions), overrideCheck];
};

const renderNavItems = ({ depth = 0, items, pathname }) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }), []
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const key = `${item.title}-${depth}`;
  const exactMatch = item.path ? !!matchPath({
    path: item.path,
    end: true
  }, pathname) : false;

  const [itemPermissions, overrideCheck] = extractItemPermissions(item);
  const adminSectionTitles = ['Repository Management', 'App Management'];
  const { permissions: currentPermissions, user } = useAuth();

  if (item.children) {
    const partialMatch = item.path ? !!matchPath({
      path: item.path,
      end: false
    }, pathname) : false;

    // This check is added for hiding App and Repository Management from non-super admins, as an easy way around the perms.
    // TODO: view_object permissions should be revisited and maybe separated to view_object_ui and view_object_detail perms.
    const makeInvisible = (adminSectionTitles.includes(item.title) && !user.is_super_admin);

    if (!makeInvisible) {
      acc.push(
        <PermissionGate
          key={key}
          checkAll={false}
          overrideCheck={overrideCheck}
          permissions={itemPermissions}
        >
          <NavItem
            active={partialMatch}
            depth={depth}
            icon={item.icon}
            info={item.info}
            key={key}
            open={partialMatch}
            path={item.path}
            title={item.title}
          >
            {renderNavItems({
              depth: depth + 1,
              items: item.children,
              pathname
            })}
          </NavItem>
        </PermissionGate>
      );
    }
  } else {
    acc.push(
      <PermissionGate
        key={key}
        checkAll={true}
        permissions={itemPermissions}
      >
        <NavItem
          active={exactMatch}
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          path={item.path}
          title={item.title}
        />
      </PermissionGate>
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, ...other } = props;
  const [sectionPermissions, overrideCheck] = extractSectionPermissions(items);

  return (
    <PermissionGate
      checkAll={false}
      overrideCheck={overrideCheck}
      permissions={sectionPermissions}
    >
      <List
        subheader={(
          <ListSubheader
            disableGutters
            disableSticky
            sx={{
              color: 'text.primary',
              fontSize: '0.75rem',
              lineHeight: 2.5,
              fontWeight: 700,
              textTransform: 'uppercase'
            }}
          >
            {title}
          </ListSubheader>
        )}
        {...other}
      >
        {renderNavItems({
          items,
          pathname
        })}
      </List>
    </PermissionGate>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string
};

export default NavSection;
