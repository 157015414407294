import {
  TableBody
} from '@material-ui/core'
import AdvancedFilterRow from './AdvancedFilterRow';
import AdvancedFilterGroupFooter from './AdvancedFilterGroupFooter';


const AdvancedFilterGroup = ({
  filterGroup,
  headers,
  dispatchFilterGroups
}) => (
  <TableBody
  style={{
    border: "2px solid rgb(200, 200, 200)",
  }}
  >
    {filterGroup.filters.map( each_filter => (
      <AdvancedFilterRow 
        key={each_filter.id}
        filter={each_filter}
        filterGroup_id={filterGroup.id}
        dispatchFilterGroups={dispatchFilterGroups}
        headers={headers}
      />
    ))}

    <AdvancedFilterGroupFooter
      filterGroup_id={filterGroup.id}
      dispatchFilterGroups={dispatchFilterGroups}
      headers={headers}
    />

  </TableBody>
);

export default AdvancedFilterGroup;