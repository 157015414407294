import createGenericError from "src/hooks/createGenericError";


const NotFound = (props) => createGenericError({
  code: 'Http404',
  title: 'Page Not Found',
  message: 'Page at the requested URL does not exist.',
});


export default NotFound;
