import PropType from "prop-types";
import { checkPermissions } from "./checkPermissions";
import useAuth from "src/hooks/useAuth";

const PermissionGate = ({
  checkAll = true,
  children,
  fallback = null,
  overrideCheck = false,
  permissions,
}) => {
  const { permissions: currentPermissions, user } = useAuth();
  return (overrideCheck || user.is_superuser || checkPermissions(currentPermissions, permissions, checkAll))
    ? children
    : fallback;
};

PermissionGate.propTypes = {
  checkAll: PropType.bool,
  children: PropType.node.isRequired,
  permissions: PropType.oneOfType([
    PropType.string, PropType.arrayOf(PropType.string)
  ]).isRequired,
  fallback: PropType.node
};

export default PermissionGate;
