import {
    Autocomplete, TextField
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getCompanyList } from '../../api/queries';

const OwnerAutocomplete = (props) => {
    const { disabled, errors, required, handleBlur, initialOwner, setErrors, setFieldValue, touched } = props;
    const [isFirst, setIsFirst] = useState(true);
    const [value, setValue] = useState(initialOwner);
    const [search, setSearch] = useState('');
    let queryParams = `page_size=300&name__icontains=${search}`;
    const query = useQuery(
      [getCompanyList.key, 'autocomplete', { params: queryParams }],
      () => getCompanyList.func(queryParams)
    );

    const labels = props?.label ?? 'Owner';
    const fieldValue = props?.fieldValue ?? 'owner';
    const administrativeCompaniesOnly = props?.administrativeCompaniesOnly ?? false;

    if (administrativeCompaniesOnly) {
        queryParams += `&parent__isnull=True`;
    }


    if (query.isError) {
      setErrors({ owner: 'Could not retrieve company list.' });
    }

    const options = isFirst && initialOwner
      ? [initialOwner]
      : (query.isFetching ? [] : query.data.results);

    return (
      <Autocomplete
        id='owner-autocomplete'
        disabled={disabled || query.isError}
        loading={query.isFetching}
        loadingText='Loading companies...'
        autoSelect={true}
        onInputChange={(_, v) => {
          setSearch(v);
          setIsFirst(false);
        }}
        onChange={(_, v) => {
          setValue(v);
          setFieldValue(fieldValue, v || null);
        }}
        onOpen={handleBlur}
        options={options}
        renderInput={(params) => (
          <TextField {...params}
            label={labels}
            error={Boolean(touched.owner && errors.owner)}
            helperText={touched.owner && errors.owner}
            fullWidth
            name='owner'
            variant='outlined'
            required={required}
          />
        )}
        getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option?.name ?? ''}
        value={value}
      />
    );
  };

export default OwnerAutocomplete;