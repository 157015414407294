import {
  TableHead,
  TableRow,
  TableCell,
  Box,
  Checkbox,
  FormControlLabel
} from '@material-ui/core'

import { OrderingIconButton } from '.';

const ResultTableHeader = ({
  headers,
  setHovered,
  hovered,
  setOrdering,
  ordering,
  isSelectable,
  selectableField,
  dispatchSelectedRows,
  selectedRows,
  query_results,
}) => (
  <TableHead>
    <TableRow>
      {isSelectable ? (
        <TableCell
          width="10%"
          align="left"
        >
          <FormControlLabel
            control={
              <Checkbox
                indeterminate={
                  query_results.map((each_row) => selectedRows.has(each_row[selectableField])).includes(false)
                  &&
                  query_results.map((each_row) => selectedRows.has(each_row[selectableField])).includes(true)
                }
                name="select-all"
                color="primary"
                checked={!query_results.map((each_row) => selectedRows.has(each_row[selectableField])).includes(false)}
                onChange={() => dispatchSelectedRows({
                  type: "TOGGLE_SELECT_ALL",
                  query_results: query_results,
                  selectableField: selectableField,
                  select: query_results.map((each_row) => selectedRows.has(each_row[selectableField])).includes(false),
                })}
              />
            }
            label="Select All"
          />
        </TableCell>
      ) : null}

      {headers.map((item) => {
        const align = item?.align || 'left';
        return (
          <TableCell
            key={item.key}
            align={align}
            onMouseEnter={() => setHovered(item.key)}
            onMouseLeave={() => setHovered(null)}
          >
            <Box>
              {item.name}
              <Box
                sx={{
                  display: 'inline',
                  marginLeft: 1
                }}
              >
                <OrderingIconButton
                  fieldKey={item.key}
                  ordering={ordering}
                  setOrdering={setOrdering}
                  visible={item.sortable && hovered === item.key}
                />      

              </Box>
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

export default ResultTableHeader;