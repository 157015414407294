import {
  Box, TableCell, TableRow
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useState } from 'react';
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useNavigate } from 'react-router-dom';
import {
  getDeviceList
} from "src/api/queries";
import {
  ActionButton,
  CheckboxDisplay, SimpleListTable
} from "src/components/common";
import PermissionGate from "src/permission-management/PermissionGate";
import CustomizableList from "../core/customizable-list/CustomizableList";
import { stringOperators } from "src/constants/TableConstants";

const ModelTypeDevicesList = ({modelType}) => {
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [queryParams, setQueryParams] = useState('');
  const queryClient = useQueryClient();

  const query = {
    key: [getDeviceList.key, 'model-type'],
    func: (params) => {
      let processedParams = `model_type=${modelType.id}`;
      if (params) {
        processedParams = `${processedParams}&${params}`;
      }
      return getDeviceList.func(processedParams);
    }
  };

  const tableHeaders = [
    {
      machineName: 'device__fiscal_id',
      humanName: 'Device Fiscal ID',
      sortable: true
    },
    {
      machineName: 'device__serial_id',
      humanName: 'Device Serial ID',
      sortable: true
    },
    {
      machineName: 'device__model_type__name',
      humanName: 'Model Type',
    },
    {
      machineName: 'device__owner__name',
      humanName: 'Owner',
    },
    {
      machineName: 'status',
      humanName: 'Status',
      sortable: false
    },
    {
      machineName: 'actions',
      humanName: 'Actions'
    }
  ];

  const filterHeaders = [
    {
      machineName: 'fiscal_id',
      humanName: 'Fiscal ID',
      operators: stringOperators,
      inputType: 'text'
    },
    {
      machineName: 'serial_id',
      humanName: 'Serial ID',
      operators: stringOperators,
      inputType: 'text'
    },
    {
      machineName: 'owner__name',
      humanName: 'Owner Company Name',
      operators: stringOperators,
      inputType: 'text'
    },
    {
      machineName: 'model_type__name',
      humanName: 'Model Type Name',
      operators: stringOperators,
      inputType: 'text'
    },
  ];

  const customRowRenderer = (data) => (
    <TableRow key={`devices-${data.id}`}>
      <TableCell>
        {data.fiscal_id}
      </TableCell>
      <TableCell>
        {data.serial_id}
      </TableCell>
      <TableCell>
        {data.model_type?.name}
      </TableCell>
      <TableCell>
        {data.owner.name}
      </TableCell>
      <TableCell>
        <CheckboxDisplay checked={Boolean(data.status)}/>
      </TableCell>
      <TableCell>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start'
          }}
        >
          <PermissionGate permissions={'commonlib.view_device'}>
            <Box mr={1}>
              <ActionButton
                label="View Device"
                action={() => navigate(`/dashboard/devices/${data.fiscal_id}`)}
              />
            </Box>
          </PermissionGate>
        </Box>
      </TableCell>
    </TableRow>
  );

  return (
    <CustomizableList
      title={`Devices with Model Type ${modelType.name}`}
      tableHeaders={tableHeaders}
      filterHeaders={filterHeaders}
      customRowRenderer={customRowRenderer}
      queryDef={query}
    />
  );
};

export default ModelTypeDevicesList;
