import { FormControl, IconButton, InputLabel, MenuItem, Select, Switch, TableCell, TableRow, TextField } from "@material-ui/core";
import { AddCircle, Clear, RemoveCircle } from "@material-ui/icons";
import {useContext, useEffect} from "react";
import { ComplexFilterContext } from "./ComplexFilterContext";
import DateTimePicker from "@material-ui/lab/DateTimePicker";
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";


const ComplexFilterRow = ({groupId, filter: thisFilter}) => {
  const {filterHeaders, setFilter, removeFilter} = useContext(ComplexFilterContext);
  const {filterId} = thisFilter;
  const selectedHeader = filterHeaders.filter((header) => header.machineName === thisFilter.column)[0];

  const dateTimePickerStyle = {
      marginTop:'20px'
};

   useEffect(() => {
    // This effect will run when the datetime is selected
    if (selectedHeader.inputType === 'datetime' && thisFilter.value === undefined) {
      // Set the initial value to the current date if it's undefined
      setFilter(groupId, filterId, {
        ...thisFilter,
        value: new Date(),
      });
    }
  }, [selectedHeader]);

  return (
    <TableRow>
      <TableCell
        align="center"
        width="5%"
      >
        <Switch
          size="small"
          color="primary"
          checked={thisFilter.active}
          onClick={() => {
            setFilter(groupId, filterId, {
              ...thisFilter,
              active: !thisFilter.active
            })
          }}
        />
      </TableCell>

      <TableCell
        align="center"
        width="20%"
      >
        <Select
          value={thisFilter.column}
          fullWidth
          onChange={(event) => {
            const newColumn = event.target.value;
            setFilter(groupId, filterId, {
              ...thisFilter,
              column: newColumn
            });
          }}
        >
          {
            filterHeaders.map((header) => (
              <MenuItem
                key={header.machineName}
                value={header.machineName}
              >
                {header.humanName}
              </MenuItem>
            ))
          }
        </Select>
      </TableCell>
      <TableCell
        align="center"
        width="5%"
      >
        <IconButton
          onClick={() => {
            setFilter(groupId, filterId, {
              ...thisFilter,
              type: !thisFilter.type
            })
          }}
        >
          {
            thisFilter.type
            ? <AddCircle color="primary" />
            : <RemoveCircle color="secondary" />
          }
        </IconButton>
      </TableCell>
      <TableCell
        align="center"
        width="20%"
      >
        <Select
          value={thisFilter.operator}
          fullWidth
          onChange={(event) => {
            const newValue = event.target.value;
            setFilter(groupId, filterId, {
              ...thisFilter,
              operator: newValue
            });
          }}
        >
          {
            selectedHeader.operators.map(
              (operator) => (
                <MenuItem
                  key={operator.machineName}
                  value={operator.machineName}
                >
                  {operator.humanName}
                </MenuItem>
              )
            )
          }
        </Select>
      </TableCell>
      <TableCell
        align="center"
        width="45%"
      >
        {
          // Sneaky way of having switches in JSX
          // https://stackoverflow.com/a/51432223
          {
            'text': (
              <TextField
                label="Value"
                fullWidth
                value={thisFilter.value}
                type="text"
                onChange={(event) => {
                  const newValue = event.target.value;
                  setFilter(groupId, filterId, {
                    ...thisFilter,
                    value: newValue
                  });
                }}
              />
            ),
            'number': (
              <TextField
                label="Value"
                fullWidth
                value={thisFilter.value}
                type="number"
                onChange={(event) => {
                  const newValue = event.target.value;
                  setFilter(groupId, filterId, {
                    ...thisFilter,
                    value: newValue
                  });
                }}
              />
            ),
            'boolean': (
              <FormControl fullWidth>
                <InputLabel>Value</InputLabel>
                <Select
                  fullWidth
                  value={thisFilter.value}
                  label="Value"
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setFilter(groupId, filterId, {
                      ...thisFilter,
                      value: newValue
                    });
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </Select>
              </FormControl>
            ),
            'choices': (
              <FormControl fullWidth>
                <InputLabel>Value</InputLabel>
                <Select
                  fullWidth
                  value={thisFilter.value}
                  label="Value"
                  onChange={(event) => {
                    const newValue = event.target.value;
                    setFilter(groupId, filterId, {
                      ...thisFilter,
                      value: newValue
                    });
                  }}
                >
                  {
                    // inputType : 'choices' expects an argument `choices` in tableHeaders 
                    // which is a list of objects with key and values.
                    // key is displayed as the choice, and value is used inside the filter.
                    selectedHeader.choices?.map((choice) => (
                      <MenuItem value={choice.value}>{choice.key}</MenuItem>
                    ))
                  }                  
                </Select>
              </FormControl>
            ),
            'datetime': (
            <div style={dateTimePickerStyle}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Value"
                  ampm={false}
                  value={thisFilter.value || new Date()}
                  onChange={(newValue) => {
                    setFilter(groupId, filterId, {
                      ...thisFilter,
                      value: newValue
                    });
                  }}
                />
              </LocalizationProvider>
            </div>
            ),
          }[selectedHeader.inputType]
        }

      </TableCell>
      <TableCell
        align="center"
        width="5%"
      >
        <IconButton
          onClick={() => {
            removeFilter(groupId, filterId);
          }}
        >
          <Clear
            fontSize="small"
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export {
  ComplexFilterRow as default
};
