import { experimentalStyled } from '@material-ui/core/styles';

const LogoRoot = experimentalStyled('svg')();

const MDMLogoHorizontalWithTitle = (props) => {
  /*
      MDM Logo Horizontal with `Token MDM` title.
      Transformed from SVG to React Component using https://svg2jsx.com/
      --------------------------------
      Example usage:

        <MDMLogoHorizontalWithTitle
          sx={{
            height: 200
          }}
        />
    */

  const { color } = props;
  return (
    <LogoRoot
      width="1401.307"
      height="240.704"
      preserveAspectRatio="xMidYMid"
      version="1"
      viewBox="0 0 1050.98 180.528"
      {...props}
    >
      <title>Token MDM</title>
      <g
        fill={color || "#007398"}
        fillOpacity="1"
        stroke="none"
        strokeDasharray="none"
        transform="matrix(.1 0 0 -.1 -40.246 289.403)"
      >
        <path
          strokeWidth="5.041"
          d="M2445.556 2514.276c-17.644-17.644-18.904-46.631-2.52-73.098 8.822-13.863 46.63-20.164 153.756-25.206l142.415-6.301 6.301-415.9 6.302-415.9 35.288-3.78c71.837-8.823 71.837-11.343 71.837 433.544v408.337h137.373c151.236 0 170.141 8.822 162.58 71.838l-3.782 35.288-346.583 3.78c-259.622 2.522-351.624-1.26-362.967-12.602z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M3599.993 2513.016c-126.03-47.892-220.553-131.072-283.568-250.8-32.768-61.755-35.288-78.14-35.288-211.731 0-141.154 1.26-147.456 42.85-224.334 49.151-90.742 122.249-162.579 218.032-212.991 61.755-32.768 76.878-35.288 211.73-35.288 128.552 0 152.497 3.78 207.95 31.507 79.4 39.07 194.087 152.497 231.896 229.375 56.714 119.729 57.974 302.473 1.26 419.68-41.59 84.44-128.55 173.922-216.772 220.553-73.097 39.07-89.481 44.111-205.429 46.632-84.44 2.52-141.154-1.26-172.661-12.603zm267.184-117.208c94.523-27.727 204.169-137.373 231.896-231.896 42.85-144.935 15.123-259.622-92.003-366.748-76.878-76.878-157.537-112.167-254.58-112.167-126.03 0-260.883 86.961-322.638 206.69-27.726 52.932-34.028 80.66-34.028 158.798 0 115.948 36.549 197.867 123.51 273.485 97.043 85.7 218.032 110.907 347.843 71.838z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M4487.245 2514.276c-21.425-21.425-21.425-906.157 0-927.582 18.905-18.905 76.879-18.905 95.783 0 10.083 10.082 15.124 68.056 15.124 180.223v165.1l44.11 42.85c26.467 25.206 47.892 37.809 54.194 30.247 6.301-6.302 80.659-105.865 165.1-221.813 136.112-186.525 158.797-211.731 190.305-211.731 42.85 0 68.056 27.727 57.974 65.536-3.781 16.384-80.66 128.55-170.141 249.54l-163.84 220.552 170.141 170.141c166.36 167.62 189.046 201.648 153.757 236.937-41.59 41.59-75.618 18.905-289.87-195.347l-211.73-211.73v196.606c0 209.21-5.041 225.595-63.015 225.595-17.644 0-39.07-6.302-47.892-15.124z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M5349.292 2509.235c-11.343-30.248-8.822-903.637 2.52-922.541 8.823-11.343 84.44-15.124 337.762-12.603l326.418 3.78 3.78 39.07c2.521 21.425-3.78 45.37-13.863 52.933-11.342 10.082-105.865 15.123-281.047 17.644l-263.403 3.78v289.87l195.347 6.302c107.125 3.78 202.908 11.342 211.73 16.384 7.562 6.301 15.124 26.466 15.124 46.63 0 20.166-7.562 40.33-15.123 46.632-8.823 5.041-104.606 12.603-211.731 16.384l-195.347 6.301v289.87l263.403 3.78c175.182 2.521 269.705 7.563 281.047 17.645 10.083 7.562 16.384 31.508 13.864 52.933l-3.781 39.07-328.939 3.78c-292.39 2.52-330.2 1.26-337.76-17.644z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M6261.75 2497.892c-16.383-44.11-16.383-850.704 0-893.554 8.823-25.206 21.426-32.768 47.892-32.768 64.276 0 65.536 5.041 65.536 368.008 0 185.265 3.78 337.761 10.082 336.5 5.041 0 124.77-149.975 265.924-333.98 142.414-182.743 267.184-341.541 279.787-352.884 12.603-11.343 37.81-17.644 57.974-15.123l35.289 3.78v945.227l-35.289 3.78c-70.577 8.823-71.837 5.042-71.837-364.226 0-186.525-3.781-339.022-8.822-339.022-6.302 1.26-132.332 158.798-281.048 350.364-247.019 317.596-274.746 349.104-312.555 352.885-34.028 3.78-42.85-1.26-52.932-28.987z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M7775.373 2505.454c-18.904-34.028-18.904-875.91-1.26-909.938 15.124-27.727 74.358-32.768 98.304-8.822 11.342 11.342 15.123 99.563 15.123 317.596l1.26 303.733 160.059-315.076c156.277-306.253 161.319-315.076 200.388-318.856l40.33-3.781 161.318 318.856 161.319 318.857 6.301-315.076c3.781-172.661 8.823-316.336 10.083-318.856 1.26-2.521 23.946-2.521 50.412 0l46.631 3.78v945.227h-88.22l-189.046-374.31c-103.345-206.69-191.566-375.57-195.347-375.57-3.781 0-90.742 168.88-195.347 375.57-184.004 365.488-190.306 374.31-229.375 378.09-28.987 2.522-42.85-2.52-52.933-21.424z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M8987.784 2515.536c-12.603-12.603-16.384-123.51-16.384-466.312 0-379.35 2.52-451.188 18.904-465.05 12.604-10.083 69.317-13.864 197.868-10.083 177.703 3.78 181.483 5.04 259.622 46.63 93.262 50.413 168.881 131.072 215.512 228.116 28.986 60.494 32.768 85.7 32.768 201.648 0 117.208-3.782 141.154-34.029 204.169-41.59 88.22-134.852 185.264-225.594 231.895-64.275 34.028-79.398 36.55-250.8 40.33-136.112 3.78-186.524 0-197.867-11.343zm390.694-142.414c180.223-88.221 255.84-299.952 168.88-476.394-65.536-134.852-171.401-199.128-339.021-209.21l-110.907-6.302v738.537l105.866-6.301c80.659-3.781 120.989-12.603 175.182-40.33z"
        ></path>
        <path
          strokeWidth="5.041"
          d="M9948.134 2513.016c-3.78-7.562-5.04-221.814-3.78-475.134l3.78-460.01 47.891-3.781c25.207-2.521 47.892-2.521 49.152 0 1.26 2.52 6.302 146.195 10.083 318.856l6.302 315.076 161.318-318.857 161.318-318.856 40.33 3.78c39.069 3.782 44.11 12.604 200.388 318.857l160.058 315.076 1.26-302.473c0-219.292 3.782-307.514 15.124-318.856 23.946-23.946 83.18-18.905 98.304 8.822 7.561 16.384 12.603 184.004 12.603 457.49 0 475.133-1.26 482.695-69.317 473.873-35.289-3.78-47.891-23.946-228.114-380.611l-190.306-376.83-146.195 282.307c-79.399 155.017-166.359 326.418-192.826 380.611-45.37 92.002-50.412 97.044-90.742 97.044-22.685 0-44.11-7.562-46.63-16.384z"
        ></path>
        <path
          stroke={color || "#007398"}
          strokeOpacity="1"
          strokeWidth="29.215"
          d="M1073.643 2655.429c-119.198-119.199-217.362-220.284-217.362-223.79 0-3.506 6.428-12.27 14.608-19.866l15.192-14.024 202.754 202.755 202.754 202.754 87.647-87.646 87.645-87.646-203.338-203.338-203.339-203.34 18.114-16.944 18.113-16.945 173.54 172.37c95.241 94.658 187.562 185.226 204.506 201.002l30.384 29.215 96.411-96.41 96.41-96.41 17.53 17.528 17.529 17.53-220.868 220.867-220.284 220.284z"
        ></path>
        <path
          stroke={color || "#007398"}
          strokeOpacity="1"
          strokeWidth="29.215"
          d="M1388 2122.541l-113.355-113.355 16.945-18.114 16.944-18.113 98.164 98.163 98.163 97.58 192.237-195.16 192.237-195.742-298.58-299.165-299.166-299.749-87.645 87.646-87.646 87.646 203.338 203.339 203.339 203.338-17.53 16.36-17.528 16.946-202.755-199.833c-111.018-109.265-203.923-199.249-206.26-199.249-1.753 0-42.654 38.565-89.983 86.478l-85.893 85.893 104.59 104.59 104.591 104.592-111.602 115.692-111.603 116.277-19.282-18.698-19.282-18.697 96.41-96.995 96.411-96.41-87.646-87.647-87.646-87.646-193.99 194.574-194.574 193.99 96.41 96.41 96.412 96.411-19.283 18.698-18.697 19.282-105.76-105.175c-57.846-57.846-108.097-109.85-111.018-115.693-5.259-9.348 30.968-47.913 255.926-274.624 143.74-144.908 342.404-342.988 440.567-440.567l178.214-176.46 438.23 437.645c240.734 240.734 438.23 440.567 439.398 442.905.585 2.921-48.497 56.093-109.265 118.614l-110.434 113.355-19.866-19.282-19.282-18.698 96.41-96.41 96.41-96.41-87.645-87.647-87.646-87.646-210.35 210.35c-115.693 115.693-211.52 210.35-213.273 210.35-1.752 0-54.34-51.418-116.86-113.939z"
        ></path>
      </g>
    </LogoRoot>
  );
}

export default MDMLogoHorizontalWithTitle;
