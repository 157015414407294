import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  Checkbox,
} from '@material-ui/core';
import { ActionButton, CheckboxDisplay } from '../common';
import { useNavigate } from 'react-router';

const ResultTableRow = ({
  entity,
  headers,
  detailUrl,
  detailUrlField,
  isSelectable,
  selectableField,
  dispatchSelectedRows,
  selectedRows,
}) => {

  const navigate = useNavigate();

  return (
    <TableRow
      hover
    >
      {isSelectable ? (
        <TableCell>
          <Checkbox
            color="primary"
            checked={selectedRows.has(entity[selectableField])}
            onChange={() => dispatchSelectedRows({
              type: "TOGGLE_ROW",
              selectableFieldValue: entity[selectableField]
            })}         
          />
        </TableCell>
      ) : null}
      {
      // TODO: noDisplay is a bandaid to `sortable` being used to separate `Action` from other columns, fix this properly
      headers
        .filter((header) => !header.noDisplay )
        .map((header) => {
          const itemVal = entity[header.key];
          return (
            <TableCell
              key={`${entity.id}${itemVal}`}
            >
              {
                itemVal
                ? ('foreignField' in header 
                  ? itemVal[header.foreignField] 
                  : (itemVal === true || itemVal === false)
                    ? <CheckboxDisplay checked={itemVal} />
                    : itemVal
                  )
                : '-'
              }
            </TableCell>
          );
        })}

      <TableCell>
        <ActionButton
          label="View Details"
          action={() => navigate(`${detailUrl}${entity[detailUrlField]}`)}
        />
      </TableCell>

    </TableRow>
  );
};

/*
ResultTableRow.propTypes = {
  entity: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired
};
*/

export default ResultTableRow;
