import {Link as RouterLink} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {Box, Card, CardContent, Container, Divider, Link, Typography} from '@material-ui/core';
import LoginJWT from '../../components/authentication/LoginJWT';
import {CompanyLogoRectangleWithTitle, MDMLogoRectangleWithTitle} from 'src/components/logo';
import useSettings from "../../hooks/useSettings";

const platformIcon = '/static/icons/jwt.svg';

const Login = () => {
    const {settings} = useSettings();

    return (
        <>
            <Helmet>
                <title>Login</title>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh'
                }}
            >
                <Container
                    maxWidth="sm"
                    sx={{py: '60px'}}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mb: 5
                        }}
                    >
                        <RouterLink to="/" style={{'textDecoration':'none'}}>
                            {settings.companyName === "TOKEN MDM" ? (
                                <MDMLogoRectangleWithTitle
                                    sx={{
                                        height: 225
                                    }}
                                />
                            ) : (
                                <CompanyLogoRectangleWithTitle
                                    color={settings.theme === "LIGHT" ? settings.themeColor : "#ffffff"}
                                    companyName={settings.companyName}
                                    logoUrl={settings.logo}
                                    sx={{
                                        mt: 1,
                                        height: 225,
                                        width: 240
                                    }}
                                />
                            )}

                        </RouterLink>
                    </Box>
                    <Card>
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                p: 4
                            }}
                        >
                            <Box
                                sx={{
                                    alignItems: 'center',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    mb: 3
                                }}
                            >
                                <div>
                                    <Typography
                                        color="textPrimary"
                                        gutterBottom
                                        variant="h4"
                                    >
                                        Log in
                                    </Typography>
                                </div>
                                <Box
                                    sx={{
                                        height: 32,
                                        '& > img': {
                                            maxHeight: '100%',
                                            width: 'auto'
                                        }
                                    }}
                                >
                                    <img
                                        alt="Auth platform"
                                        src={platformIcon}
                                    />
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    mt: 3
                                }}
                            >
                                <LoginJWT/>
                                <Divider sx={{my: 3}}/>
                                <Link
                                    color="textSecondary"
                                    component={RouterLink}
                                    to="/authentication/password/reset"
                                    variant="body2"
                                >
                                    Forgot your password?
                                </Link>
                            </Box>
                        </CardContent>
                    </Card>
                </Container>
            </Box>
        </>

    )
}

export default Login;
