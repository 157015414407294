import { useState, memo } from 'react';
import { SimpleSearchBar } from 'src/components/common';

const useSimpleSearchBar = () => {
  const [searchParams, setSearchParams] = useState('');
  const simpleSearchBar = <SimpleSearchBar searchParams={searchParams} setSearchParams={setSearchParams} />

  return [simpleSearchBar, searchParams];
};

export default useSimpleSearchBar;
