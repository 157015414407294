/*
  Objects encapsulating key-function pairs for
  API calls with effect, e.g. post/put
*/

import axios from '../lib/axios';

export const passivateDeviceApplication = {
  key: 'passivate-device-application',
  func: async (device, deviceApplication) => {
    const formData = new FormData();
    formData.append('device_application_id', deviceApplication.id);
    formData.append('fiscal_id', device.fiscal_id);
    return axios.post(`/manager/device-applications/${deviceApplication.id}/passivate_application/`, formData);
  }
};

export const uninstallDeviceApplication = {
  key: 'uninstall-device-application',
  func: async (device, deviceApplication) => {
    const formData = new FormData();
    formData.append('device_application_id', deviceApplication.id);
    formData.append('fiscal_id', device.fiscal_id);
    return axios.post(`/manager/device-applications/${deviceApplication.id}/uninstall_application/`, formData);
  }
};

export const assignDeviceApplication = {
  key: 'assign-device-application',
  func: async (formData) => {
    return axios.post(`/manager/device-applications/assign_application/`, formData);
  }
};

export const assignApplicationToDevices = {
  key: 'assign-application-to-devices',
  func: async (formData, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    return axios.post(`/manager/devices/assign_application_to_devices/${suffix}`, formData);
  }
};

export const assignDeviceOTAApplication = {
  key: 'assign-device-ota-application',
  func: async (formData) => {
    return axios.post(`/manager/device-applications/assign_ota_application/`, formData);
  }
};

export const handleDeviceApplicationIntervention = {
  key: 'handle-device-application-intervention',
  func: async (deviceApplication, decision, device) => {
    const formData = new FormData();
    formData.append('device_application_id', deviceApplication.id);
    formData.append('decision', decision);
    formData.append('fiscal_id', device.fiscal_id);
    return axios.post(`/manager/device-applications/${deviceApplication.id}/handle_intervention/`, formData);
  }
};

export const assignOTAApplicationToDevices = {
  key: 'assign-ota-application-to-devices',
  func: async (formData, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    return axios.post(`/manager/devices/assign_ota_application_to_devices/${suffix}`, formData);
  }
};

export const createApplication = {
  key: 'create-application',
  func: async (formData) => {
    return axios.post('/manager/applications/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export const editApplication = {
  key: 'edit-application',
  func: async (appId, formData) => {
    return axios.patch(`/manager/applications/${appId}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export const uploadVersion = {
  key: 'upload-version',
  func: async ({appId, formData, setProgress}) => {
    return axios.post(`/manager/versions/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (setProgress) {
          setProgress(percentCompleted);
        }
      }
    });
  }
};

export const editVersion = {
  key: 'edit-version',
  func: async (versionId, formData) => {
    const response = await axios.patch(`/manager/versions/${versionId}/`, formData);
    return response;
  }
};

export const deleteVersion = {
  key: 'delete-version',
  func: async (versionId) => {
    const response = await axios.delete(`/manager/versions/${versionId}/`);
    return response;
  }
};

export const triggerDeviceSync = {
  key: 'trigger-device-sync',
  func: async (device, formData) => {
    return axios.post(`/manager/devices/${device.fiscal_id}/trigger_device_sync/`, formData);
  }
};

export const triggerDeviceLog = {
  key: 'trigger-device-log',
  func: async (device, formData) => {
    return axios.post(`/manager/devices/${device.fiscal_id}/trigger_device_log/`, formData);
  }
};

export const revokeScheduledAppAssignmentForDevice = {
  key: 'revoke-scheduled-app-assignment-for-device',
  func: async ({fiscalId, formData}) => {
    const response = await axios.delete(`/manager/devices/${fiscalId}/scheduled_app_assignments/`, {data: formData});
    return response;
  }
};

export const uploadDevices = {
  key: 'upload-devices',
  func: async ({formData, setProgress}) => {
    return axios.post(`/manager/devices/bulk_create_devices_from_excel/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob',
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (setProgress) {
          setProgress(percentCompleted);
        }
      }
    });
  }
};

export const createCompany = {
  key: 'create-company',
  func: async (formData) => {
    return axios.post('/manager/companies/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export const editCompany = {
  key: 'edit-company',
  func: async (companyId, formData) => {
    return axios.patch(`/manager/companies/${companyId}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export const deleteCompany = {
  key: 'delete-company',
  func: async (companyId) => {
    const response = await axios.delete(`/manager/companies/${companyId}/`);
    return response;
  }
};


export const createIncident = {
  key: 'create-incident',
  func: async (formData) => {
    const response = await axios.post('/manager/incidents/', formData);
    return response;
  }
};

export const editIncident = {
  key: 'edit-incident',
  func: async (incidentId, formData) => {
    const response = await axios.patch(`/manager/incidents/${incidentId}/`, formData);
    return response;
  }
};

export const deleteIncident = {
  key: 'delete-incident',
  func: async (incidentId) => {
    const response = await axios.delete(`/manager/incidents/${incidentId}/`);
    return response;
  }
};

export const createIncidentType = {
  key: 'create-incident-type',
  func: async (formData) => {
    const response = await axios.post('/manager/incident-type/', formData);
    return response;
  }
}

export const editIncidentType = {
  key: 'edit-incident-type',
  func: async (incidentTypeId, formData) => {
    const response = await axios.patch(`/manager/incident-type/${incidentTypeId}/`, formData);
    return response;
  }
};

export const deleteIncidentType = {
  key: 'delete-incident-type',
  func: async (incidentTypeId) => {
    const response = await axios.delete(`/manager/incident-type/${incidentTypeId}/`);
    return response;
  }
};

export const createRepository = {
  key: 'create-repository',
  func: async (formData) => {
    const response = await axios.post('/manager/repositories/', formData);
    return response;
  }
};

export const editRepository = {
  key: 'edit-repository',
  func: async (repositoryId, formData) => {
    const response = await axios.patch(`/manager/repositories/${repositoryId}/`, formData);
    return response;
  }
};

export const deleteRepository = {
  key: 'delete-repository',
  func: async (repositoryId) => {
    const response = await axios.delete(`/manager/repositories/${repositoryId}/`);
    return response;
  }
};

export const deleteApplication = {
  key: 'delete-application',
  func: async (applicationId) => {
    const response = await axios.delete(`/manager/applications/${applicationId}/`);
    return response;
  }
};

export const createRepositoryApplication = {
  key: 'create-repository-application',
  func: async (formData) => {
    const response = await axios.post('/manager/repository-applications/', formData);
    return response;
  }
};

export const deleteRepositoryApplication = {
  key: 'delete-repository-application',
  func: async (repositoryApplicationId) => {
    const response = await axios.delete(`/manager/repository-applications/${repositoryApplicationId}`);
    return response;
  }
};

export const bulkAssignDevicesToRepository = {
  key: 'bulk-assign-devices-to-repository',
  func: async (repositoryId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.post(`/manager/devices/bulk_assign_devices_to_repository/${repositoryId}/${suffix}`);
    return response;
  }
};

export const bulkUnassignDevicesFromRepository = {
  key: 'bulk-unassign-devices-from-repository',
  func: async (repositoryId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.delete(`/manager/devices/bulk_unassign_devices_from_repository/${repositoryId}/${suffix}`);
    return response;
  }
};

export const createRepositoryDevice = {
  key: 'create-repository-device',
  func: async (formData) => {
    const response = await axios.post('/manager/repository-devices/', formData);
    return response;
  }
};

export const deleteRepositoryDevice = {
  key: 'delete-repository-device',
  func: async (repositoryDeviceId) => {
    const response = await axios.delete(`/manager/repository-devices/${repositoryDeviceId}`);
    return response;
  }
};

export const createUser = {
  key: 'create-user',
  func: async (formData) => {
    const response = await axios.post('/manager/users/', formData);
    return response;
  }
}

export const editUserProfile = {
  key: 'edit-user-profile',
  func: async (userId, formData) => {
    const response = await axios.patch(`/manager/users/${userId}/`, formData);
    return response;
  }
};

export const deleteUser = {
  key: 'delete-user',
  func: async (userId) => {
    const response = await axios.delete(`/manager/users/${userId}/`);
    return response;
  }
};

export const assignUserToCompany = {
  key: 'assign-users-to-company',
  func: async (userId, companyId) => {
    const suffix = `?id=${userId}`;
    const response = await axios.post(`/manager/users/assign_user_to_company/${companyId}/${suffix}`);
    return response;
  }
};

export const unassignUserFromCompany = {
  key: 'unassign-users-from-company',
  func: async (userId, companyId) => {
    const suffix = `?id=${userId}`;
    const response = await axios.delete(`/manager/users/unassign_user_from_company/${companyId}/${suffix}`);
    return response;
  }
};

export const createRole = {
  key: 'create-role',
  func: async (formData) => {
    const response = await axios.post('/manager/groups/', formData);
    return response;
  }
};

export const editRoleUsers = {
  key: 'edit-role-users',
  func: async (roleId, formData) => {
    const response = await axios.patch(`/manager/groups/${roleId}/users/`, formData);
    return response;
  }
};

export const editRolePermissions = {
  key: 'edit-role-permissions',
  func: async (roleId, formData) => {
    const response = await axios.patch(`/manager/groups/${roleId}/permissions/`, formData);
    return response;
  }
};

export const deleteRole = {
  key: 'delete-role',
  func: async (roleId) => {
    const response = await axios.delete(`/manager/groups/${roleId}/`);
    return response;
  }
};

export const createTag = {
  key: 'create-tag',
  func: async (formData) => {
    const response = await axios.post('/manager/tags/', formData);
    return response;
  }
};

export const editTag = {
  key: 'edit-tag',
  func: async (tagId, formData) => {
    const response = await axios.patch(`/manager/tags/${tagId}/`, formData);
    return response;
  }
};

export const deleteTag = {
  key: 'delete-tag',
  func: async (tagId) => {
    const response = await axios.delete(`/manager/tags/${tagId}/`);
    return response;
  }
};

export const deleteDeviceTag = {
  key: 'delete-device-tag',
  func: async (deviceTagId) => {
    const response = await axios.delete(`/manager/device-tags/${deviceTagId}/`);
    return response;
  }
};

export const bulkAssignDevicesToTag = {
  key: 'bulk-assign-devices-to-tag',
  func: async (tagId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.post(`/manager/devices/bulk_assign_devices_to_tag/${tagId}/${suffix}`);
    return response;
  }
};

export const bulkAssignDevicesToCompany = {
  key: 'bulk-assign-devices-to-company',
  func: async (ownerId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.post(`/manager/devices/bulk_assign_devices_to_company/${ownerId}/${suffix}`);
    return response;
  }
};

export const bulkUnassignDevicesFromCompany = {
  key: 'bulk-unassign-devices-from-company',
  func: async (ownerId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.post(`/manager/devices/bulk_unassign_devices_from_company/${ownerId}/${suffix}`);
    return response;
  }
};

export const deleteMerchant = {
  key: 'delete-merchant',
  func: async (merchantId) => {
    const response = await axios.delete(`/manager/merchants/${merchantId}/`);
    return response;
  }
};

export const bulkAssignDevicesToMerchant = {
  key: 'bulk-assign-devices-to-merchant',
  func: async (merchantId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.post(`/manager/devices/bulk_assign_devices_to_merchant/${merchantId}/${suffix}`);
    return response;
  }
};

export const bulkUnassignDevicesFromMerchant = {
  key: 'bulk-unassign-devices-from-merchant',
  func: async (merchantId, queryParams) => {
    const suffix = queryParams ? `?${queryParams}` : '';
    const response = await axios.post(`/manager/devices/bulk_unassign_devices_from_merchant/${merchantId}/${suffix}`);
    return response;
  }
};

export const createMerchant = {
  key: 'create-merchant',
  func: async (formData) => {
    const response = await axios.post('/manager/companies/', formData);
    return response;
  }
};

export const editMerchant = {
  key: 'edit-merchant',
  func: async (companyId, formData) => {
    const response = await axios.patch(`/manager/companies/${companyId}/`, formData);
    return response;
  }
};

export const uploadDeviceStores = {
  key: 'upload-device-stores',
  func: async ({formData, setProgress}) => {
    return axios.post(`/manager/companies/bulk_create_device_stores_from_excel/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob',
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (setProgress) {
          setProgress(percentCompleted);
        }
      }
    });
  }
};

export const uploadMerchants = {
  key: 'upload-merchants',
  func: async ({formData, setProgress}) => {
    return axios.post(`/manager/companies/bulk_create_merchants_from_excel/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob',
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (setProgress) {
          setProgress(percentCompleted);
        }
      }
    });
  }
};

export const createRepositoryAccess = {
  key: 'create-repository-access',
  func: async (formData) => {
    const response = await axios.post('/manager/repository-accesses/', formData);
    return response;
  }
};

export const deleteRepositoryAccess = {
  key: 'delete-repository-access',
  func: async (repositoryAccessId) => {
    const response = await axios.delete(`/manager/repository-accesses/${repositoryAccessId}/`);
    return response;
  }
};

export const createDevice = {
  key: 'create-device',
  func: async (formData) => {
    const response = await axios.post('/manager/devices/', formData);
    return response;
  }
};

export const editDevice = {
  key: 'edit-device',
  func: async (fiscalId, formData) => {
    const response = await axios.patch(`/manager/devices/${fiscalId}/`, formData);
    return response;
  }
};

export const deleteDevice = {
  key: 'delete-device',
  func: async (fiscalId) => {
    const response = await axios.delete(`/manager/devices/${fiscalId}/`);
    return response;
  }
};

export const createApplicationAccess = {
  key: 'create-application-access',
  func: async (formData) => {
    const response = await axios.post('/manager/application-accesses/', formData);
    return response;
  }
};

export const deleteApplicationAccess = {
  key: 'delete-application-access',
  func: async (applicationAccessId) => {
    const response = await axios.delete(`/manager/application-accesses/${applicationAccessId}/`);
    return response;
  }
};

export const createParameter = {
  key: 'create-parameter',
  func: async (formData) => {
    const response = await axios.post('/manager/parameters/', formData);
    return response;
  }
};

export const deleteParameter = {
  key: 'delete-parameter',
  func: async (parameterId) => {
    const response = await axios.delete(`/manager/parameters/${parameterId}/`);
    return response;
  }
};

export const editParameter = {
  key: 'edit-parameter',
  func: async (parameterId, formData) => {
    const response = await axios.patch(`/manager/parameters/${parameterId}/`, formData);
    return response;
  }
};

export const editDeviceParameter = {
  key: 'edit-device-parameter',
  func: async (deviceParameterId, formData) => {
    const response = await axios.patch(`/manager/device-parameters/${deviceParameterId}/`, formData);
    return response;
  }
};

export const createModelType = {
  key: 'create-model-type',
  func: async (formData) => {
    const response = await axios.post('/manager/model-types/', formData);
    return response;
  }
};

export const editModelType = {
  key: 'edit-model-type',
  func: async (modelTypeId, formData) => {
    const response = await axios.patch(`/manager/model-types/${modelTypeId}/`, formData);
    return response;
  }
};

export const deleteModelType = {
  key: 'delete-model-type',
  func: async (modelTypeId) => {
    const response = await axios.delete(`/manager/model-types/${modelTypeId}/`);
    return response;
  }
};

export const sendMerchantInfoViaEmail = {
  key: 'send-merchant-info-email',
  func: async (company, formData) => {
    return axios.post(`/manager/companies/${company.id}/send_merchant_info_email/`, formData);
  }
};

export const createDeviceStore = {
  key: 'create-device-store',
  func: async (formData) => {
    const response = await axios.post('/manager/device-stores/', formData);
    return response;
  }
};

export const editDeviceStore = {
  key: 'edit-device-store',
  func: async (deviceStoreId, formData) => {
    const response = await axios.patch(`/manager/device-stores/${deviceStoreId}/`, formData);
    return response;
  }
};

export const deleteDeviceStore = {
  key: 'delete-device-store',
  func: async (deviceStoreId) => {
    const response = await axios.delete(`/manager/device-stores/${deviceStoreId}`);
    return response;
  }
};

export const replaceDevice = {
  key: 'replace-device',
  func: async (formData) => {
    const response = await axios.post('/manager/devices/replace_device/', formData);
    return response;
  }
};

export const unmarkReplacedDevice = {
  key: 'unmark-replaced-device',
  func: async (deviceId) => {
    const formData = new FormData();
    formData.append('device_id', deviceId);
    const response = await axios.post('/manager/devices/unmark_replaced_device/', formData);
    return response;
  }
};

export const createBank = {
  key: 'create-bank',
  func: async (formData) => {
    const response = await axios.post('/manager/banks/', formData);
    return response;
  }
};

export const editBank = {
  key: 'edit-bank',
  func: async (bankId, formData) => {
    const response = await axios.patch(`/manager/banks/${bankId}/`, formData);
    return response;
  }
};

export const deleteBank = {
  key: 'delete-bank',
  func: async (bankId) => {
    const response = await axios.delete(`/manager/banks/${bankId}/`);
    return response;
  }
};

export const editBankDeviceStatus = {
  key: 'edit-bank-device-status',
  func: async (bankDeviceId, formData) => {
    const response = await axios.patch(`/manager/bank-devices/${bankDeviceId}/`, formData);
    return response;
  }
};

export const createBankDevice = {
  key: 'create-bank-devices',
  func: async (formData) => {
    const response = await axios.post('/manager/bank-devices/', formData);
    return response;
  }
};

export const editBankDevice = {
  key: 'edit-bank-devices',
  func: async (bankDeviceId, formData) => {
    const response = await axios.patch(`/manager/bank-devices/${bankDeviceId}/`, formData);
    return response;
  }
};

export const createSimStock = {
  key: 'create-sim-stock',
  func: async (formData) => {
    const response = await axios.post('/manager/sim-stocks/', formData);
    return response;
  }
};

export const editSimStock = {
  key: 'edit-sim-stock',
  func: async (simStockId, formData) => {
    const response = await axios.patch(`/manager/sim-stocks/${simStockId}/`, formData);
    return response;
  }
};

export const deleteSim = {
  key: 'delete-sim',
  func: async (simStockId) => {
    const response = await axios.delete(`/manager/sim-stocks/${simStockId}/`);
    return response;
  }
};

export const uploadSim = {
  key: 'upload-sims',
  func: async ({formData, setProgress}) => {
    return axios.post(`/manager/sim-stocks/bulk_create_sim_from_excel/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob',
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (setProgress) {
          setProgress(percentCompleted);
        }
      }
    });
  }
};

export const createConsumable = {
  key: 'create-consumable',
  func: async (formData) => {
    const response = await axios.post('/manager/consumable/', formData);
    return response;
  }
};

export const editConsumable = {
  key: 'edit-consumable',
  func: async (consumableId, formData) => {
    const response = await axios.patch(`/manager/consumable/${consumableId}/`, formData);
    return response;
  }
};

export const deleteConsumable = {
  key: 'delete-consumable',
  func: async (consumableId) => {
    const response = await axios.delete(`/manager/consumable/${consumableId}/`);
    return response;
  }
};

export const createCompanySlaTime = {
  key: 'create-company-sla-time',
  func: async (formData) => {
    const response = await axios.post('/manager/company-sla-time/', formData);
    return response;
  }
};

export const editCompanySlaTime = {
  key: 'edit-company-sla-time',
  func: async (companySlaTimeId, formData) => {
    const response = await axios.patch(`/manager/company-sla-time/${companySlaTimeId}/`, formData);
    return response;
  }
};

export const deleteCompanySlaTime = {
  key: 'delete-company-sla-time',
  func: async (companySlaTimeId) => {
    const response = await axios.delete(`/manager/company-sla-time/${companySlaTimeId}/`);
    return response;
  }
};