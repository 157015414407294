import {Box, Button, Collapse, Divider, Stack, Tooltip} from "@material-ui/core";
import {useState} from "react";
import {ActionButton, SimpleSearchBar} from "src/components/common";
import {ComplexFilter, ComplexFilterContextProvider} from "../complex-filter";
import FilterListTwoToneIcon from '@material-ui/icons/FilterListTwoTone';
import ManageSearch from '@material-ui/icons/ManageSearch'
import DeviceSearchForm from "../../device/DeviceSearchForm";
import DeviceSearchDownloadButton from "../../device/DeviceSearchDownloadButton";
import {useSnackbar} from "notistack";
import {useQuery} from "react-query";
import {getSearchFile} from "../../../api/queries";
import DownloadIcon from "../../../icons/Download";
import PermissionGate from "../../../permission-management/PermissionGate";


const CustomizableListSearch = ({
                                  disableSearch,
                                  disableComplexFilter,
                                  advancedSearch,
                                  toggleSearch,
                                  setToggleSearch,
                                  searchParams,
                                  setSearchParams,
                                  setFilterParams,
                                  filterHeaders,
                                  openCollapse,
                                  setOpenCollapse,
                                  filterGroups,
                                  setFilterGroups,
                                  setBodyParams,
                                  setPage,
                                  bodyParams,
                                  queryParams
                                }) => {
  const [internalFilterParams, setInternalFilterParams] = useState('');

  if (disableSearch && !filterHeaders && !advancedSearch) {
    return null;
  }

  const handleCollapseToggle = () => {setOpenCollapse(!openCollapse); setToggleSearch(false); };

  const handleAdvancedSearch = () => {setToggleSearch(!toggleSearch); setOpenCollapse(false)};

  return (
    <Stack>
      {
          <Box sx={{
            p: 3,
            display: 'flex',
            justifyContent: 'flex-end',
            mr: 3,
            gap: 2,
            alignItems: 'center'
        }}>
            {
                !disableSearch && (
                    <SimpleSearchBar
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setPage={setPage}
                    />
                )
            }
            {
                !disableComplexFilter && filterHeaders && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<FilterListTwoToneIcon fontSize="large"/>}
                        onClick={handleCollapseToggle}
                        size="large"
                    >
                        Filter
                    </Button>
                )
            }
            {
                advancedSearch && (
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ManageSearch fontSize="large"/>}
                        onClick={handleAdvancedSearch}
                        size="large"
                    >
                        Advanced Search
                    </Button>
                )
            }
        </Box>
      }
      {
        filterHeaders && (
          <Box sx={{p: 1}}>
            <Collapse in={openCollapse} timeout="auto">
              <ComplexFilterContextProvider
                initialFilterGroups={filterGroups}
                filterHeaders={filterHeaders}
                setFilterQueryParams={setInternalFilterParams}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start"
                  }}
                >
                  <ComplexFilter
                    internalFilterParams={internalFilterParams}
                    setFilterParams={setFilterParams}
                    setInitialFilterGroups={setFilterGroups}
                    setPage={setPage}
                    setBodyParams={setBodyParams}
                  />
                </Box>
              </ComplexFilterContextProvider>
              <Divider/>
            </Collapse>
          </Box>
        )
      }
      {
        advancedSearch && (
        <Box sx={{ mt: 3 , mb: 3}}>
          <Collapse in={toggleSearch} timeout="auto">
            <Box sx={{gap:2, display:'flex', flexDirection:'column'}}>
              <DeviceSearchForm
                setBodyParams={setBodyParams}
                                setPage={setPage}
                setFilterParams={setFilterParams}
                bodyParams={bodyParams}
                queryParams={queryParams}
              />
              <PermissionGate
                permissions={'commonlib.download_device_search'}
              >
                <Box sx={{display:'flex',justifyContent:'end'}}>
                  <DeviceSearchDownloadButton
                    queryParams={queryParams}
                    bodyParams={bodyParams}
                  />
                </Box>
              </PermissionGate>
            </Box>
          </Collapse>
        </Box>
        )
      }
    </Stack>
  );
};

export default CustomizableListSearch;
