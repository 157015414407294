import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {useRef, useState} from 'react';
import packageJson from '../../../../package.json';
import {getServiceMasterOTP, getSystemVersionList} from "../../../api/queries";
import InformationCircle from "../../../icons/InformationCircle";
import CustomizableList from "../../core/customizable-list/CustomizableList";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {serviceMasterOtpRefresh} from "../../../api/auth";
import PermissionGate from "../../../permission-management/PermissionGate";


const SystemInfoPopover = () => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const mutation = useMutation(
    serviceMasterOtpRefresh.func,
    {
      mutationKey: serviceMasterOtpRefresh.key,
      onSuccess: () =>
        queryClient.invalidateQueries([getServiceMasterOTP.key]),
    }
  );

  const serviceMasterOtpQuery = useQuery(
    getServiceMasterOTP.key,
    getServiceMasterOTP.func
  );

  const serviceMasterOtp = serviceMasterOtpQuery.data;
  if (serviceMasterOtpQuery.isError) {
    const serviceMasterOtp = []
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const tableHeaders = [
    {
      machineName: 'display_name',
      humanName: 'Module'
    },
    {
      machineName: 'version',
      humanName: 'Version'
    },
  ];

  const customRowRenderer = (item) => {
    return (
      <TableRow key={`system-module-${item.id}`}>
        <TableCell>
          {item.display_name}
        </TableCell>
        <TableCell>
          {item.version}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <InformationCircle/>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {width: 500}
        }}
        sx={{
          '& .MuiPaper-root': {
            mt: 0
          }
        }}
      >
        <CustomizableList
          tableHeaders={tableHeaders}
          disableSearch={true}
          disablePagination={true}
          customRowRenderer={customRowRenderer}
          queryDef={getSystemVersionList}
          title={`System & Module Info`}
        />
        <Card>
          <CardContent>
            <Divider/>
            <Table>
              <TableHead>
                <TableCell>
                  <Typography variant="button">
                    UI Version
                  </Typography>

                </TableCell>
                <TableCell>
                  <Typography variant="button">
                    Environment
                  </Typography>
                </TableCell>
              </TableHead>

              <TableBody>
                <TableCell>
                  {packageJson.version}
                </TableCell>
                <TableCell>
                  {process.env.REACT_APP_ENVIRONMENT}
                </TableCell>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        <PermissionGate permissions={'commonlib.can_access_service_master_otp'}>
          <Box sx={{p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <Typography
              color="textSecondary"
              variant="button"
              align="left"
            >
              Service Master OTP: {serviceMasterOtp ? serviceMasterOtp['service_master_otp']: ""}
            </Typography>
            <Button
              sx={{alignSelf: 'right'}}
              color="primary"
              fullWidth={false}
              onClick={() => {
                // handleClose();
                mutation.mutate(null, {
                  onSuccess: () => {

                  },
                  onError: (error) => {
                    console.error(error);
                  },
                });
              }}
              variant="outlined"
            >
              Change OTP
            </Button>
          </Box>
        </PermissionGate>
      </Popover>
    </>
  );
};

export default SystemInfoPopover;
