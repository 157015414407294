import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

const CheckboxDisplay = (props) => {
  const { checked } = props;

  return (
    <Checkbox
      disabled
      checked={checked}
    />
  );
};

CheckboxDisplay.propTypes = {
  checked: PropTypes.bool.isRequired
};

export default CheckboxDisplay;
