/*
  Checks if required permissions exist in the current permissions
  without caring about where those permissions come from.
*/
export const checkPermissions = (
  currentPermissions, /* set of strings */
  requiredPermissions = null, /* string[] | string | null */
  checkAll = true /* bool */
) => {
  // There are no required permissions
  if (!requiredPermissions || (Array.isArray(requiredPermissions) && requiredPermissions.length === 0)) {
    return true;
  }

  // Convert to array if necessary for simpler handling later
  if (!Array.isArray(requiredPermissions)) {
    requiredPermissions = [requiredPermissions];
  }

  // Every required permission must be present in the current permissions
  if (checkAll) {
    return requiredPermissions.every((p) => currentPermissions.has(p));
  }
  
  // It is enough to have a required permission in the current permissions
  return requiredPermissions.some((p) => currentPermissions.has(p));
};
