import axios from 'axios';
import { tokenRefresh } from 'src/api/auth';

axios.defaults.withCredentials = true

/*
    Axios instance intended to be used for authentication processes.
    The main axios instance below uses this axios instance to try to refresh
    if the access token has expired.

    Related, the AuthContext instances attaches an interceptor to this instance
    to automatically logout if refresh fails, i.e. the refresh token has expired too.
    
    But failed refresh and say, a failed login both returns 403 status code.
    So we set the 'noExpire' option here to be used for all authentication processes
    and we explicitly set the call for token refresh to have 'noExpire' set to 'false'
    so it can trigger expiration.
*/
export const authAxios = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    noExpire: true
});

/*
    Axios instance intended to be used for API calls other than authentication processes.
*/
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        'Accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use((response) => response,
    async (error) => {
        const originalRequest = error.config;
        // If error reason was unauthorized access try to refresh the access token
        // _.retry variable is to only try to refresh once
        if (!originalRequest.retry && error.response.status === 403 && error.response.data.errors[0].title === 'NotAuthenticated') {
            originalRequest.retry = true;
            // Where the token refresh happens
            await tokenRefresh.func();
            // Retry the original request
            return axiosInstance(originalRequest);
        }
        // Error cause was different, no need to try to refresh
        return Promise.reject(error);
    });

export default axiosInstance;
