import {useSnackbar} from "notistack";
import {useQuery} from "react-query";
import {getSearchFile} from "../../api/queries";
import {ActionButton} from "../common";
import DownloadIcon from "../../icons/Download";

const DeviceSearchDownloadButton = ({ queryParams, bodyParams }) => {
  const { enqueueSnackbar } = useSnackbar();

  const query = useQuery(
    getSearchFile.key,
    () => getSearchFile.func(queryParams, bodyParams),
    {
      enabled: false,
      onSuccess: (result) => {
        const { blob, filename } = result;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      },
      onError: (error) => {
        console.error(error);
        enqueueSnackbar('Download Failed', {
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top'
          },
          variant: 'error'
        });
      }
    }
  );

  return (

      <ActionButton
        label={
          <>
            <DownloadIcon fontSize="small" />
            Download
          </>
        }
        action={query.refetch}
      />
  );
};

export default DeviceSearchDownloadButton;