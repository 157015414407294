import {
  TableFooter,
  TableRow,
  TablePagination
} from '@material-ui/core';


const ResultTableFooter = ({
  query_count,
  page,
  setPage,
  pageSize,
  setPageSize,
  defaultPageSize
}) => (
  <TableFooter>
    <TableRow>
      <TablePagination
        count={query_count}
        page={page}
        onPageChange={(e, newPage) => setPage(newPage)}
        rowsPerPage={pageSize}
        onRowsPerPageChange={(e) => {
          setPageSize(parseInt(e.target.value, defaultPageSize));
          setPage(0);
        }}
        rowsPerPageOptions={[5, 10, 20, 50]}
      />
    </TableRow>
  </TableFooter>
)


export default ResultTableFooter;