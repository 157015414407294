import {
    Autocomplete, TextField
} from "@material-ui/core";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getModelTypeList } from 'src/api/queries';
  

const ModelTypeAutocomplete = (props) => {
    const { disabled, errors, required, handleBlur, setFieldValue, touched, initialModelType} = props;
    const [value, setValue] = useState(initialModelType);
    const [search, setSearch] = useState('');
    const queryParams = `page_size=300&name__icontains=${search}`;

    const query = useQuery(
      [getModelTypeList.key, 'autocomplete', {params: queryParams}],
      () => getModelTypeList.func(queryParams)
    );
  
    if (query.isError) {
      console.error(query.error);
    }
  
    const options = (query.isFetching ? [] : query.data.results);

    return (
      <Autocomplete
        id='modeltype-autocomplete'
        disabled={disabled || query.isError}
        loading={query.isFetching}
        loadingText='Loading model types...'
        autoSelect={true}
        onInputChange={(_, v) => {
          setSearch(v);
        }}
        onChange={(_, v) => {
          setValue(v);
          setFieldValue('model_type', v || null);
        }}
        onOpen={handleBlur}
        options={options}
        renderInput={(params) => (
          <TextField {...params}
            label='Model Type'
            error={Boolean(touched.model_type && errors.model_type)}
            helperText={touched.model_type && errors.model_type}
            fullWidth
            name='model_type'
            variant='outlined'
            required={required}
          />
        )}
        getOptionLabel={(option) => option?.name || ''}
        getOptionSelected={(option, value) => option.id === value.id}
        value={value}
      />
    );
  };
  
export default ModelTypeAutocomplete;