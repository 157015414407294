import {
  TableBody,
} from '@material-ui/core';
import ResultTableRow from './ResultTableRow';

const ResultTableBody = ({
  query_results,
  isSelectable,
  selectableField,
  dispatchSelectedRows,
  selectedRows,
  headers,
  detailUrl,
  detailUrlField,
}) => (
  (Array.isArray(query_results) && query_results.length)
  ? (
    <TableBody>
      {query_results.map((entity) => (
        <ResultTableRow
          key={entity.id}
          entity={entity}
          headers={headers}
          detailUrl={detailUrl}
          detailUrlField={detailUrlField}
          isSelectable={isSelectable}
          selectableField={selectableField}
          dispatchSelectedRows={dispatchSelectedRows}
          selectedRows={selectedRows}
        />
      ))}
    </TableBody>
  )
  : null
);

export default ResultTableBody;