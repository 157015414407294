import { Box, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useState } from "react";
import { OrderingIconButton } from "src/components/common";
import PopoverIcon from "src/components/common/PopoverIcon";
import InfoIcon from '@material-ui/icons/Info';


const CustomizableListHeader = ({
  tableHeaders,
  ordering,
  setOrdering,
}) => {
  const [hovered, setHovered] = useState(null);

  return (
    <TableHead>
      <TableRow>
        {
          Array.isArray(tableHeaders) && tableHeaders.map(({machineName, humanName, sortable, style, info}) => (
            <TableCell
              key={machineName}
              onMouseEnter={() => setHovered(machineName)}
              onMouseLeave={() => setHovered(null)}
              {...style}
            >
              <Box
                sx={{
                  justifyContent: 'center'
                }}
              >
                <Typography variant="button" {...style}>
                  {humanName}
                </Typography>
                {
                  sortable && (
                    <Box
                      sx={{
                        display: 'inline',
                        ml: 1,
                      }}
                    >
                      <OrderingIconButton
                        fieldKey={machineName}
                        ordering={ordering}
                        setOrdering={setOrdering}
                        visible={hovered === machineName}
                      />
                    </Box>
                  )
                }
                {
                  info && (
                    <PopoverIcon
                      text={info}
                      icon={<InfoIcon fontSize='small'/>}
                      style={{
                        display: 'inline-flex',
                        ml: 1
                      }}
                    />
                  )
                }
              </Box>
            </TableCell>
          ))
        }
      </TableRow>
    </TableHead>
  );
};

export default CustomizableListHeader;
